import React from "react";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react";

const forms = [
    {
        label: "Email address",
        type: "email",
        field: "email",
    },
    {
        label: "First name",
        type: "text",
        field: "firstName",
    },
    {
        label: "Last name",
        type: "text",
        field: "lastName",
    },
    {
        label: "Phone number",
        type: "tel",
        field: "phoneNumber",
    },
    {
        label: "Zip code",
        type: "text",
        field: "postalCode",
    },
];

export const DetailsInputForm = ({
    customerDetails,
    onCustomerDetailsChange,
    onSubmit,
    spotsRemaining,
    type,
}) => {
    return (
        <form onSubmit={onSubmit}>
            <VStack spacing={4} align="stretch">
                {forms.map((form) => (
                    <FormControl key={form.field} id={form.field} isRequired>
                        <FormLabel>{form.label}</FormLabel>
                        <Input
                            type={form.type}
                            value={customerDetails[form.field]}
                            onChange={(e) =>
                                onCustomerDetailsChange({
                                    field: form.field,
                                    value: e.target.value,
                                })
                            }
                        />
                    </FormControl>
                ))}
                {type === "day-pass" && (
                    <QuantityInput
                        value={customerDetails.quantity}
                        onChange={onCustomerDetailsChange}
                        spotsRemaining={spotsRemaining}
                    />
                )}
                <Button type="submit" colorScheme="blue" size="lg">
                    Continue to Payment
                </Button>
            </VStack>
        </form>
    );
};

const QuantityInput = ({ value, onChange, spotsRemaining }) => {
    return (
        <FormControl>
            <FormLabel>Quantity</FormLabel>
            <NumberInput
                value={value}
                min={1}
                max={spotsRemaining}
                onChange={(value) =>
                    onChange({
                        field: "quantity",
                        value: parseInt(value),
                    })
                }
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </FormControl>
    );
};
