import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { BaseBookings } from "../BaseBookings";
import { useCheckoutContext } from "../../../context/CheckoutContext";
import { LOCATION_TO_ID, BOOKING_TYPE } from "../../../constants";

export const DayPassBookings = ({ location }) => {
    const { updateCheckoutData } = useCheckoutContext();
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    // It might be better to fetch the timezone from the backend
    // and use that to set the selected date
    const [selectedDate, setSelectedDate] = useState(
        moment().tz("America/Denver").toDate()
    );
    const [sessionDate, setSessionDate] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionStartTime, setSelectedSessionStartTime] =
        useState(null);

    useEffect(() => {
        if (redirect && selectedSessionStartTime) {
            updateCheckoutData({
                productId: "day-pass",
                locationId: LOCATION_TO_ID[location],
                sessionDate,
                sessionStartTime: selectedSessionStartTime,
            });
            navigate("/checkout/day-pass");
        }
    }, [
        redirect,
        location,
        selectedSessionStartTime,
        sessionDate,
        navigate,
        updateCheckoutData,
    ]);

    useEffect(() => {
        if (selectedDate) {
            const sessionDate = moment(selectedDate).format("YYYY-MM-DD");
            setSessionDate(sessionDate);
        }
    }, [selectedDate]);

    const handleBookingAttempt = () => {
        if (selectedSession) {
            // Create date object from selected date and hour in Mountain Time
            const selectedYearMT = parseInt(sessionDate.split("-")[0]);
            const selectedMonthMT = parseInt(sessionDate.split("-")[1]);
            const selectedDayMT = parseInt(sessionDate.split("-")[2]);
            const selectedHour = selectedSession.startHour;

            const sessionStartTimeMST = moment.tz(
                `${selectedYearMT}-${selectedMonthMT}-${selectedDayMT} ${selectedHour}:00`,
                "YYYY-MM-DD HH:mm",
                "America/Denver"
            );

            // Convert the time to UTC
            const sessionStartTimeUTC = sessionStartTimeMST
                .clone()
                .tz("UTC")
                .format("YYYY-MM-DDTHH:mm:ssZ");

            setSelectedSessionStartTime(sessionStartTimeUTC);
            setRedirect(true);
        }
    };

    const handleSelectSession = (session) => {
        if (session.status === "AVAILABLE") {
            setSelectedSession(session);
            setConfirmationModalOpen(true);
        }
    };

    return (
        <BaseBookings
            location={location}
            dateProps={{
                selectedDate: selectedDate,
                setSelectedDate: setSelectedDate,
            }}
            sessionProps={{
                sessionDate: sessionDate,
                selectedSession: selectedSession,
                sessionStartTime: selectedSessionStartTime,
                setSelectedSession: setSelectedSession,
                handleSelectSession: handleSelectSession,
            }}
            bookingProps={{
                bookingType: BOOKING_TYPE.DAY_PASS,
                handleBookingAttempt: handleBookingAttempt,
                confirmationModalOpen: confirmationModalOpen,
                setConfirmationModalOpen: setConfirmationModalOpen,
            }}
            cancelProps={{}}
        />
    );
};
