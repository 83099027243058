const CUSTOMER_API_URL = process.env.REACT_APP_CUSTOMERS_API_BASE_URL;

export const getCustomerByEmail = async ({ email, jwtToken }) => {
    const path = `${CUSTOMER_API_URL}/customer/email`;
    const response = await fetch(`${path}?email=${email}`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Failed to create customer");
    }

    return response.json();
};
