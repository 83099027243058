import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { formatTime, formatSessionDate } from "../../../utils/date";

export const DayPassConfirmationModal = ({
    isOpen,
    onClose,
    selectedSession,
    handleBookingAttempt,
    sessionDate,
}) => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    const navigate = useNavigate();

    const handleLoginClick = () => {
        onClose();
        navigate("/login");
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(10px)" />
            <ModalContent bg="gray.800" borderRadius="md" boxShadow="lg">
                <ModalHeader
                    textAlign="center"
                    fontSize="2xl"
                    fontWeight="bold"
                    color="blue.200"
                    py={6}
                >
                    Confirm Booking
                </ModalHeader>
                <ModalBody>
                    {selectedSession && (
                        <>
                            <Text
                                textAlign="center"
                                fontSize="lg"
                                color="white"
                                mb={4}
                            >
                                Are you sure you want to book the{" "}
                                <strong style={{ color: "orange.300" }}>
                                    {formatTime(selectedSession.startHour)} -{" "}
                                    {formatTime(selectedSession.endHour)}
                                </strong>{" "}
                                session on {formatSessionDate(sessionDate)}?
                            </Text>
                            <Box bg="gray.700" p={4} borderRadius="md">
                                <Text
                                    textAlign="center"
                                    fontSize="md"
                                    color="white"
                                    mb={2}
                                >
                                    <strong>Note:</strong> By confirming, you
                                    will be directed to the Day Pass purchase
                                    page.
                                </Text>
                                {authStatus !== "authenticated" && (
                                    <Text
                                        textAlign="center"
                                        fontSize="md"
                                        color="teal.300"
                                    >
                                        If you are a member, please{" "}
                                        <Button
                                            variant="link"
                                            color="blue.200"
                                            fontWeight="bold"
                                            onClick={handleLoginClick}
                                        >
                                            log in
                                        </Button>{" "}
                                        before proceeding to ensure a seamless
                                        booking experience.
                                    </Text>
                                )}
                            </Box>
                        </>
                    )}
                </ModalBody>
                <ModalFooter justifyContent="center" py={6}>
                    <Button
                        colorScheme="blue"
                        onClick={handleBookingAttempt}
                        mr={4}
                        px={8}
                        py={3}
                        fontSize="md"
                        fontWeight="semibold"
                    >
                        Confirm
                    </Button>
                    <Button
                        variant="outline"
                        onClick={onClose}
                        px={8}
                        py={3}
                        fontSize="md"
                        fontWeight="semibold"
                        color="white"
                        borderColor="white"
                        _hover={{ bg: "whiteAlpha.200" }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
