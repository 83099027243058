import { fetchAuthSession } from "@aws-amplify/auth";

const API_BASE_URL = process.env.REACT_APP_MEMBERSHIPS_API_BASE_URL;

export const createMemberBooking = async ({
    productId,
    locationId,
    sessionDate,
    sessionStartTime,
    customerId,
    waitlistBooking,
}) => {
    const path = `${API_BASE_URL}/memberships/bookings`;
    const jwtToken = await fetchAuthSession().then((authSession) =>
        authSession.tokens.idToken.toString()
    );
    const response = await fetch(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
            productId,
            locationId,
            sessionDate,
            sessionStartTime,
            customerId,
            waitlistBooking,
        }),
    });

    if (!response.ok) {
        throw new Error("Failed to create member booking");
    }

    return response.json();
};
