export const createEmailSubscription = async ({ email }) => {
    try {
        const baseUrl = process.env.REACT_APP_EMAIL_SUBSCRIPTIONS_API_BASE_URL;
        const response = await fetch(`${baseUrl}/subscribe-email`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            throw new Error("Failed to save email");
        }

        return await response.json();
    } catch (error) {
        console.error("Error in createEmailSubscription:", error);
        throw error;
    }
};
