import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { Center, Spinner } from "@chakra-ui/react";

import { useCheckoutContext } from "../../context/CheckoutContext";
import { useCustomerContext } from "../../context/CustomerContext";
import { createMembershipSubscription } from "../../api/createMembershipSubscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const isObjectComplete = (obj) =>
    Object.values(obj).every((value) => value !== "");

export const MembershipStripeWrapper = ({ children, customerDetails }) => {
    const { membershipData } = useCheckoutContext();
    const { customerData } = useCustomerContext();
    const { productId } = membershipData;
    const customerDetailsComplete = isObjectComplete(customerDetails);
    const customerDataComplete = customerData && customerData.customerId !== "";

    const { data: clientSecret, isLoading } = useQuery(
        ["clientSecret", productId, customerDetails, customerData],
        () =>
            createMembershipSubscription({
                productId,
                customerDetails,
                customerId: customerData.customerId,
            }).catch((error) => {
                console.error("Error in getClientSecret:", error);
                throw error;
            }),
        {
            enabled:
                productId && customerDetailsComplete && customerDataComplete,
            select: (data) => {
                return data.clientSecret;
            },
            onError: (error) => {
                console.error("Error in useQuery:", error);
            },
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    if (isLoading) {
        return (
            <Center h="200px">
                <Spinner size="xl" />
            </Center>
        );
    }

    const elementsOptions = {
        appearance: {
            theme: "night",
            variables: {
                colorText: "#ffffff",
            },
        },
        clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={elementsOptions}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, { clientSecret });
            })}
        </Elements>
    );
};
