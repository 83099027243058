const LOCATION_TO_ID = {
    "Fort Collins": "fort-collins",
    Loveland: "loveland",
    Greeley: "greeley",
    Windsor: "windsor",
};

const BOOKING_TYPE = {
    DAY_PASS: "day-pass",
    MEMBER: "member",
};

export { LOCATION_TO_ID, BOOKING_TYPE };
