import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { Center, Spinner } from "@chakra-ui/react";

import { useCheckoutContext } from "../../context/CheckoutContext";
import { createDayPassPaymentIntent } from "../../api/createDayPassPaymentIntent";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const isObjectComplete = (obj) =>
  Object.values(obj).every((value) => value !== "");

export const DayPassStripeWrapper = ({ children, customerDetails }) => {
  const { checkoutData } = useCheckoutContext();
  const { quantity } = customerDetails;
  const checkoutComplete = isObjectComplete(checkoutData);
  const customerDetailsComplete = isObjectComplete(customerDetails);

  const { data: clientSecret, isLoading } = useQuery(
    ["clientSecret", checkoutData, customerDetails],
    () =>
      createDayPassPaymentIntent({
        checkoutData,
        customerDetails,
      }).catch((error) => {
        console.error("Error in getClientSecret:", error);
        throw error;
      }),
    {
      enabled: checkoutComplete && customerDetailsComplete,
      select: (data) => {
        return data.clientSecret;
      },
      onError: (error) => {
        console.error("Error in useQuery:", error);
      },
    }
  );

  if (isLoading) {
    return (
      <Center h="200px">
        <Spinner size="xl" />
      </Center>
    );
  }

  const elementsOptions = {
    appearance: {
      theme: "night",
      variables: {
        colorText: "#ffffff",
      },
    },
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { clientSecret, quantity });
      })}
    </Elements>
  );
};
