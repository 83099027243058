import { Heading, Text, Box, VStack } from "@chakra-ui/react";

const PRODUCT_ID_TO_NAME = {
    monthly: "Monthly Membership",
    quarterly: "Quarterly Membership",
    yearly: "Yearly Membership",
};

const PRODUCT_ID_TO_PRICE = {
    monthly: 99.99,
    quarterly: 279.99,
    yearly: 799.99,
};

const MEMBERSHIP_MONTHS = {
    monthly: 1,
    quarterly: 3,
    yearly: 12,
};

export const MembershipDetails = ({ productId }) => {
    return (
        <Box textAlign="left">
            <Heading
                textAlign={"center"}
                as="h2"
                size="xl"
                paddingInline={4}
                mb={6}
            >
                Purchase a{" "}
                <Text as="span" color="red">
                    Fenyx
                </Text>{" "}
                {PRODUCT_ID_TO_NAME[productId]}
            </Heading>

            <Box borderWidth={2} borderRadius="md" p={6}>
                <VStack spacing={6} align="stretch">
                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Price:
                        </Text>{" "}
                        <br />
                        ${PRODUCT_ID_TO_PRICE[productId]} + tax
                    </Text>
                </VStack>
            </Box>

            <Box borderWidth={2} borderRadius="md" p={6}>
                <VStack spacing={6} align="stretch">
                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Membership renewal date:
                        </Text>{" "}
                        <br />
                        {getMembershipEndDate(productId)}
                    </Text>
                </VStack>
            </Box>
        </Box>
    );
};

const getMembershipEndDate = (productId) => {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() + MEMBERSHIP_MONTHS[productId]);
    return endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
};
