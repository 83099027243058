import { createContext, useContext, useState, useMemo } from "react";

const CheckoutContext = createContext();

export const useCheckoutContext = () => useContext(CheckoutContext);

export const CheckoutProvider = ({ children }) => {
    const [checkoutData, setCheckoutData] = useState({
        locationId: "",
        productId: "",
        sessionDate: "",
        sessionStartTime: "",
    });

    const [membershipData, setMembershipData] = useState({
        productId: "",
    });

    const memoizedCheckoutData = useMemo(() => checkoutData, [checkoutData]);
    const memoizedMembershipData = useMemo(
        () => membershipData,
        [membershipData]
    );

    const updateCheckoutData = (data) => {
        setCheckoutData({ ...checkoutData, ...data });
    };

    const updateMembershipData = (data) => {
        setMembershipData({ ...membershipData, ...data });
    };

    return (
        <CheckoutContext.Provider
            value={{
                checkoutData: memoizedCheckoutData,
                updateCheckoutData,
                membershipData: memoizedMembershipData,
                updateMembershipData,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};
