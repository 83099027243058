import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Box,
    Spinner,
    Text,
    Alert,
    AlertIcon,
    SimpleGrid,
} from "@chakra-ui/react";

import { useCustomerContext } from "../../../context/CustomerContext";
import { MemberSession } from "./MemberSession";
import { formatSessionDate } from "../../../utils/date";

export const MembershipBookingListModal = ({
    isOpen,
    onClose,
    handleSelectSession,
    setCancelConfirmationModalOpen,
    setSessionToCancel,
    localSessions,
    sessionsLoading,
    sessionsError,
    sessionDate,
}) => {
    const { customerData } = useCustomerContext();
    const { customerId } = customerData;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" bg="black">
            <ModalOverlay />
            <ModalContent
                bg="black"
                mt={40}
                borderRadius="2xl"
                borderWidth="4px"
                borderColor="white.500"
            >
                <ModalHeader>
                    Choose a session on {formatSessionDate(sessionDate)}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* Handle Loading State */}
                    {sessionsLoading && (
                        <Box textAlign="center">
                            <Spinner size="xl" />
                            <Text>Loading sessions...</Text>
                        </Box>
                    )}

                    {/* Handle Error State */}
                    {sessionsError && (
                        <Alert status="error">
                            <AlertIcon />
                            {sessionsError.message}
                        </Alert>
                    )}

                    {/* Display Sessions */}
                    {localSessions && localSessions.length > 0 ? (
                        <SimpleGrid
                            columns={{ base: 1, md: 1 }}
                            spacing={4}
                            paddingTop={2}
                            paddingBottom={4}
                            bg="black"
                        >
                            {localSessions.map((slot, index) => (
                                <MemberSession
                                    key={`${slot.startHour}-${slot.endHour}`}
                                    slot={slot}
                                    handleSelectSession={handleSelectSession}
                                    customerId={customerId}
                                    setCancelConfirmationModalOpen={
                                        setCancelConfirmationModalOpen
                                    }
                                    setSessionToCancel={setSessionToCancel}
                                    index={index}
                                />
                            ))}
                        </SimpleGrid>
                    ) : (
                        !sessionsLoading && (
                            <Text paddingTop={4} paddingBottom={4}>
                                No sessions available for this date.
                            </Text>
                        )
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
