import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    Button,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const Success = () => {
    const navigate = useNavigate();
    const [pageText, setPageText] = useState({
        message: "",
        subHeader: "",
    });
    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const textColor = useColorModeValue("gray.100", "gray.100");

    const handleBackToHome = () => {
        navigate("/");
    };

    useEffect(() => {
        const getPaymentIntent = async () => {
            const stripe = await stripePromise;
            const { paymentIntent } = await stripe.retrievePaymentIntent(
                clientSecret
            );
            switch (paymentIntent.status) {
                case "succeeded":
                    setPageText({
                        message: "Success! Payment received.",
                        subHeader: "Thank you for your purchase.",
                    });
                    break;

                case "processing":
                    setPageText({
                        message:
                            "Payment processing. We'll update you when payment is received.",
                        subHeader: "Thank you for your purchase.",
                    });
                    break;

                case "requires_payment_method":
                    setPageText({
                        message:
                            "Payment failed. Please try another payment method.",
                        subHeader: "Please try again.",
                    });
                    break;

                default:
                    setPageText({
                        message: "Something went wrong.",
                        subHeader: "Please try again.",
                    });
                    break;
            }
        };
        getPaymentIntent();
    }, []);

    return (
        <Box
            maxW={{ base: "90%", md: "600px" }}
            mx="auto"
            my={{ base: 4, md: 8 }}
            mt={12}
            p={{ base: 4, md: 8 }}
            bg={
                "linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
            }
            borderRadius="md"
            borderWidth="1px"
            boxShadow="xl"
            textAlign="center"
        >
            <VStack spacing={{ base: 4, md: 6 }}>
                <Box p={6}>
                    <CheckCircleIcon
                        boxSize={{ base: 12, md: 16 }}
                        color="green.500"
                    />
                    <Heading
                        as="h1"
                        size={{ base: "lg", md: "xl" }}
                        my={6}
                        color={textColor}
                    >
                        {pageText.message}
                    </Heading>
                    <Text fontSize={{ base: "md", md: "lg" }} color="gray.300">
                        {pageText.subHeader}
                    </Text>
                    <Button
                        onClick={handleBackToHome}
                        colorScheme="blue"
                        size={{ base: "md", md: "lg" }}
                        mt={10}
                    >
                        Back to Home
                    </Button>
                </Box>
            </VStack>
        </Box>
    );
};
