import { Box, Spinner } from "@chakra-ui/react";

export const Loading = ({ height = "100vh" }) => {
    return (
        <Box
            height={height}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="purple.500"
                size="xl"
            />
        </Box>
    );
};
