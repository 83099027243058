import {
    Box,
    Button,
    Container,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    VStack,
    FormControl,
    FormLabel,
    Spinner,
    Input,
    useToast,
    Heading,
    Text,
    Stack,
    Badge,
    Divider,
} from "@chakra-ui/react";

import { useQuery } from "react-query";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import moment from "moment-timezone";
import { getCustomerBookings } from "../api/getCustomerBookings";
import { useCustomerContext } from "../context/CustomerContext";
import { Loading } from "../components/general/Loading";
import { startTimeInFuture } from "../utils/date";
import { cancelMemberBooking } from "../api/cancelMemberbooking";
import { getMembership } from "../api/getMembership";

const LOCATION_ID_TO_NAME = {
    "fort-collins": "Fort Collins",
};

const MEMBERSHIP_TYPE_TO_NAME = {
    "four-week": "Four Week",
    "twelve-week": "Twelve Week",
};

const UserAccount = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const [jwtToken, setJwtToken] = useState(null);
    const { customerData, loading: customerLoading } = useCustomerContext();
    const { customerId } = customerData;

    useEffect(() => {
        if (authStatus !== "authenticated") {
            navigate("/");
        }
        const fetchJwtToken = async () => {
            const authSession = await fetchAuthSession();
            const jwtToken = authSession.tokens.idToken.toString();
            setJwtToken(jwtToken);
        };
        fetchJwtToken();
    }, [authStatus, customerId, navigate]);

    const { data: membership, isLoading: membershipLoading } = useQuery(
        ["membership", customerId, jwtToken],
        () => getMembership({ customerId, jwtToken }),
        {
            enabled: !!customerId && !!jwtToken,
        }
    );

    const {
        data: bookingHistory,
        isLoading: bookingHistoryLoading,
        refetch: refetchBookingHistory,
    } = useQuery(
        ["bookingHistory", customerId, jwtToken],
        () => getCustomerBookings({ customerId, jwtToken }),
        {
            enabled: !!customerId && !!jwtToken,
        }
    );

    const [localBookingHistory, setLocalBookingHistory] = useState([]);

    useEffect(() => {
        const bookingHistoryCopy = [...(bookingHistory || [])];
        bookingHistoryCopy.sort((a, b) => {
            return moment(a.sessionStartTime).diff(moment(b.sessionStartTime));
        });
        setLocalBookingHistory(bookingHistoryCopy);
    }, [bookingHistory]);

    if (bookingHistoryLoading || customerLoading || membershipLoading) {
        return <Loading />;
    }

    // Mock data - replace with actual user data
    const mockUserData = {
        paymentMethod: {
            type: "Credit Card",
            last4: "4242",
            expiryDate: "05/25",
        },
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        // Add your password change logic here
        toast({
            title: "Password updated",
            description: "Your password has been successfully changed.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Container maxW="container.lg" py={8}>
            <Heading mb={6} textAlign="center">
                My Account
            </Heading>

            <Tabs>
                <TabList justifyContent="center">
                    <Tab fontSize={{ base: "sm", md: "lg" }}>Profile</Tab>
                    <Tab fontSize={{ base: "sm", md: "lg" }}>Membership</Tab>
                    <Tab fontSize={{ base: "sm", md: "lg" }}>Sessions</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Stack spacing={6}>
                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                            >
                                <Heading size="md" mb={4}>
                                    Personal Information
                                </Heading>
                                <VStack spacing={4} align="stretch">
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            type="email"
                                            value="user@example.com"
                                            isReadOnly
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input
                                            type="text"
                                            value="John Doe"
                                            isReadOnly
                                        />
                                    </FormControl>
                                </VStack>
                            </Box>

                            {/* Change Password */}
                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                            >
                                <Heading size="md" mb={4}>
                                    Change Password
                                </Heading>
                                <form onSubmit={handlePasswordChange}>
                                    <VStack spacing={4} align="stretch">
                                        <FormControl isRequired>
                                            <FormLabel>
                                                Current Password
                                            </FormLabel>
                                            <Input
                                                type="password"
                                                placeholder="Enter current password"
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>New Password</FormLabel>
                                            <Input
                                                type="password"
                                                placeholder="Enter new password"
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>
                                                Confirm New Password
                                            </FormLabel>
                                            <Input
                                                type="password"
                                                placeholder="Confirm new password"
                                            />
                                        </FormControl>
                                        <Button
                                            type="submit"
                                            colorScheme="blue"
                                            size="md"
                                            width="fit-content"
                                        >
                                            Update Password
                                        </Button>
                                    </VStack>
                                </form>
                            </Box>
                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Stack spacing={6}>
                            {/* Membership Information */}
                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                            >
                                <Heading size="md" mb={4}>
                                    Current Membership
                                </Heading>
                                <Stack spacing={3}>
                                    <Box>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Type:{" "}
                                        </Text>
                                        <Text display="inline" ml={2}>
                                            {
                                                MEMBERSHIP_TYPE_TO_NAME[
                                                    membership.membershipType
                                                ]
                                            }
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Status:{" "}
                                        </Text>
                                        <Badge colorScheme="green" ml={2}>
                                            {membership.active
                                                ? "Active"
                                                : "Inactive"}
                                        </Badge>
                                    </Box>
                                    <Box>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Renewal Date:{" "}
                                        </Text>
                                        <Text display="inline" ml={2}>
                                            {formatRenewalTimestamp({
                                                timestamp:
                                                    membership.renewalTimestamp,
                                            })}
                                        </Text>
                                    </Box>
                                </Stack>
                            </Box>

                            {/* Payment Method */}
                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                            >
                                <Heading size="md" mb={4}>
                                    Payment Method
                                </Heading>
                                <Stack spacing={3}>
                                    <Box>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Type:{" "}
                                        </Text>
                                        <Text display="inline" ml={2}>
                                            {mockUserData.paymentMethod.type}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Card Number:{" "}
                                        </Text>
                                        <Text display="inline" ml={2}>
                                            ••••{" "}
                                            {mockUserData.paymentMethod.last4}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text
                                            fontWeight="bold"
                                            display="inline"
                                        >
                                            Expiry Date:{" "}
                                        </Text>
                                        <Text display="inline" ml={2}>
                                            {
                                                mockUserData.paymentMethod
                                                    .expiryDate
                                            }
                                        </Text>
                                    </Box>
                                    <Divider />
                                    <Button
                                        colorScheme="blue"
                                        size="sm"
                                        width="fit-content"
                                    >
                                        Update Payment Method
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </TabPanel>

                    <SessionTable
                        bookingHistory={bookingHistory}
                        localBookingHistory={localBookingHistory}
                        customerId={customerId}
                        refetchBookingHistory={refetchBookingHistory}
                        toast={toast}
                    />
                </TabPanels>
            </Tabs>
        </Container>
    );
};

export default UserAccount;

const SessionTable = ({
    bookingHistory,
    localBookingHistory,
    customerId,
    refetchBookingHistory,
    toast,
}) => {
    const [cancelling, setCancelling] = useState(true);
    const [cancellingBookingId, setCancellingBookingId] = useState(null);

    return (
        <TabPanel>
            <Box
                p={{ base: "0px", md: "5px" }}
                shadow="md"
                borderWidth={{ base: "0px", md: "1px" }}
                borderRadius={{ base: "0px", md: "md" }}
                overflowX={{ base: "scroll", md: "hidden" }}
                marginLeft={{ base: "0px", md: "auto" }}
                marginRight={{ base: "0px", md: "auto" }}
            >
                {bookingHistory.length > 0 ? (
                    <Table variant="simple" size={{ base: "sm", md: "md" }}>
                        <Thead>
                            <Tr>
                                <Th>Location</Th>
                                <Th>Date</Th>
                                <Th>Time</Th>
                                <Th>Status</Th>
                                <Th
                                    display={{ base: "none", md: "table-cell" }}
                                >
                                    Passes
                                </Th>
                                <Th
                                    display={{ base: "none", md: "table-cell" }}
                                >
                                    Actions
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {localBookingHistory.map((booking) => (
                                <Tr key={booking.bookingId}>
                                    <Td>
                                        {
                                            LOCATION_ID_TO_NAME[
                                                booking.locationId
                                            ]
                                        }
                                    </Td>
                                    <Td>
                                        {moment(booking.sessionDate).format(
                                            "M/DD/YYYY"
                                        )}
                                    </Td>
                                    <Td>
                                        {moment(
                                            booking.sessionStartTime
                                        ).format("h:mm a")}
                                    </Td>
                                    <Td>
                                        <Badge
                                            colorScheme={getStatusColor(
                                                booking.bookingStatus
                                            )}
                                        >
                                            {booking.bookingStatus}
                                        </Badge>
                                    </Td>
                                    <Td
                                        display={{
                                            base: "none",
                                            md: "table-cell",
                                        }}
                                    >
                                        {booking.customerQuantity}
                                    </Td>
                                    <Td
                                        display={{
                                            base: "none",
                                            md: "table-cell",
                                        }}
                                    >
                                        {booking.bookingStatus ===
                                            "CONFIRMED" &&
                                            startTimeInFuture({
                                                startTime:
                                                    booking.sessionStartTime,
                                            }) && (
                                                <Button
                                                    colorScheme="red"
                                                    size="xs"
                                                    onClick={async () => {
                                                        setCancelling(true);
                                                        setCancellingBookingId(
                                                            booking.bookingId
                                                        );
                                                        const response =
                                                            await cancelMemberBooking(
                                                                {
                                                                    bookingId:
                                                                        booking.bookingId,
                                                                    customerId:
                                                                        customerId,
                                                                }
                                                            );
                                                        if (response.success) {
                                                            toast({
                                                                title: "Booking Cancelled",
                                                                description:
                                                                    "Your booking has been successfully cancelled.",
                                                                status: "success",
                                                                duration: 3000,
                                                                isClosable: true,
                                                            });
                                                            refetchBookingHistory();
                                                        } else {
                                                            toast({
                                                                title: "Failed to cancel booking",
                                                                description:
                                                                    "Please try again later.",
                                                                status: "error",
                                                                duration: 3000,
                                                                isClosable: true,
                                                            });
                                                        }
                                                        setCancelling(false);
                                                        setCancellingBookingId(
                                                            null
                                                        );
                                                    }}
                                                >
                                                    {cancelling &&
                                                    cancellingBookingId ===
                                                        booking.bookingId ? (
                                                        <Box width={12}>
                                                            <Spinner size="sm" />
                                                        </Box>
                                                    ) : (
                                                        <Box width={12}>
                                                            Cancel
                                                        </Box>
                                                    )}
                                                </Button>
                                            )}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Box textAlign="center" py={10}>
                        <Text fontSize="lg" color="gray.600">
                            No bookings found
                        </Text>
                        <Button
                            colorScheme="blue"
                            size="md"
                            mt={4}
                            onClick={() => {
                                // Add navigation to booking page
                            }}
                        >
                            Book a Sauna
                        </Button>
                    </Box>
                )}
            </Box>
        </TabPanel>
    );
};

const getStatusColor = (status) => {
    switch (status) {
        case "CONFIRMED":
            return "blue";
        case "CANCELLED":
            return "red";
        case "WAITLISTED":
            return "yellow";
        default:
            return "gray";
    }
};

const formatRenewalTimestamp = ({ timestamp }) => {
    const renewalMoment = moment.tz(timestamp * 1000, "America/Denver");
    return renewalMoment.isValid() ? renewalMoment.format("MM/DD/YYYY") : "N/A";
};
