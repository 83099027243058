import { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { BaseBookings } from "../BaseBookings";
import { BOOKING_TYPE } from "../../../constants";
// import { useCheckoutContext } from "../../context/CheckoutContext";
import { LOCATION_TO_ID } from "../../../constants";
import { useCustomerContext } from "../../../context/CustomerContext";
import { createMemberBooking } from "../../../api/createMemberBooking";
import { cancelMemberBooking } from "../../../api/cancelMemberbooking";
import { Loading } from "../../general/Loading";

export const MemberBookings = ({ location }) => {
    const { customerData, loading } = useCustomerContext();
    const { customerId } = customerData;
    const [memberhipBookingLoading, setMemberhipBookingLoading] =
        useState(false);
    // const { updateCheckoutData } = useCheckoutContext();
    // const navigate = useNavigate();
    // const [redirect, setRedirect] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [cancelConfirmationModalOpen, setCancelConfirmationModalOpen] =
        useState(false);
    // It might be better to fetch the timezone from the backend
    // and use that to set the selected date
    const [selectedDate, setSelectedDate] = useState(
        moment().tz("America/Denver").toDate()
    );
    const [sessionDate, setSessionDate] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionStartTime, setSelectedSessionStartTime] =
        useState(null);
    const [bookingConfirmed, setBookingConfirmed] = useState(false);
    const [bookingCancelled, setBookingCancelled] = useState(false);
    const [sessionToCancel, setSessionToCancel] = useState(null);

    // useEffect(() => {
    //     if (redirect && selectedSessionStartTime) {
    //         updateCheckoutData({
    //             productId: "day-pass",
    //             locationId: LOCATION_TO_ID[location],
    //             sessionDate,
    //             sessionStartTime: selectedSessionStartTime,
    //         });
    //         navigate("/checkout/day-pass");
    //     }
    // }, [
    //     redirect,
    //     location,
    //     selectedSessionStartTime,
    //     sessionDate,
    //     navigate,
    //     updateCheckoutData,
    // ]);

    useEffect(() => {
        if (selectedDate) {
            const sessionDate = moment(selectedDate).format("YYYY-MM-DD");
            setSessionDate(sessionDate);
        }
    }, [selectedDate]);

    const handleBookingAttempt = async () => {
        setMemberhipBookingLoading(true);
        const response = await createMemberBooking({
            locationId: LOCATION_TO_ID[location],
            sessionDate,
            sessionStartTime: selectedSessionStartTime,
            customerId,
            waitlistBooking: selectedSession.status === "WAITLIST",
        });
        if (response.success) {
            setBookingConfirmed(true);
        }
        setMemberhipBookingLoading(false);
        return response;
    };

    const handleCancelBooking = async () => {
        const bookingId = getBookingIdToCancel({ sessionToCancel });
        setMemberhipBookingLoading(true);
        const response = await cancelMemberBooking({
            bookingId,
            customerId,
        });
        setMemberhipBookingLoading(false);
        if (response.success) {
            setBookingCancelled(true);
        }
        return response;
    };

    const getBookingIdToCancel = ({ sessionToCancel }) => {
        const confirmedSet = sessionToCancel.confirmedSet;
        for (const confirmedSession of confirmedSet) {
            if (confirmedSession.customerId === customerId) {
                return confirmedSession.bookingId;
            }
        }
        for (const waitlistSession of sessionToCancel.waitlistSet) {
            if (waitlistSession.customerId === customerId) {
                return waitlistSession.bookingId;
            }
        }

        throw new Error("No booking id found");
    };

    const setSessionStartTime = useCallback(() => {
        if (selectedSession) {
            // Create date object from selected date and hour in Mountain Time
            const selectedYearMT = parseInt(sessionDate.split("-")[0]);
            const selectedMonthMT = parseInt(sessionDate.split("-")[1]);
            const selectedDayMT = parseInt(sessionDate.split("-")[2]);
            const selectedHour = selectedSession.startHour;

            const sessionStartTimeMST = moment.tz(
                `${selectedYearMT}-${selectedMonthMT}-${selectedDayMT} ${selectedHour}:00`,
                "YYYY-MM-DD HH:mm",
                "America/Denver"
            );

            // Convert the time to UTC
            const sessionStartTimeUTC = sessionStartTimeMST
                .clone()
                .tz("UTC")
                .format("YYYY-MM-DDTHH:mm:ssZ");

            setSelectedSessionStartTime(sessionStartTimeUTC);
        }
    }, [sessionDate, selectedSession]);

    useEffect(() => {
        if (sessionDate && selectedSession) {
            setSessionStartTime();
        }
    }, [sessionDate, selectedSession, setSessionStartTime]);

    const handleSelectSession = (session) => {
        if (["AVAILABLE", "WAITLIST"].includes(session.status)) {
            setSelectedSession(session);
            setConfirmationModalOpen(true);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <BaseBookings
            location={location}
            dateProps={{
                selectedDate,
                setSelectedDate,
            }}
            sessionProps={{
                sessionDate,
                selectedSession,
                sessionStartTime: selectedSessionStartTime,
                setSelectedSession,
                handleSelectSession,
            }}
            bookingProps={{
                bookingType: BOOKING_TYPE.MEMBER,
                handleBookingAttempt,
                confirmationModalOpen,
                setConfirmationModalOpen,
                sessionStartTime: selectedSessionStartTime,
                bookingConfirmed,
                setBookingConfirmed,
                memberhipBookingLoading,
            }}
            cancelProps={{
                handleCancelBooking,
                bookingCancelled,
                setSessionToCancel,
                setBookingCancelled,
                cancelConfirmationModalOpen,
                setCancelConfirmationModalOpen,
                sessionToCancel,
            }}
        />
    );
};
