const API_BASE_URL = process.env.REACT_APP_PAYMENTS_API_BASE_URL;

export const createDayPassPaymentIntent = async ({
    checkoutData,
    customerDetails,
}) => {
    const { productId, locationId, sessionDate, sessionStartTime } =
        checkoutData;
    const { email, firstName, lastName, phoneNumber, quantity, postalCode } =
        customerDetails;
    const path = `${API_BASE_URL}/create-payment-intent/day-pass`;
    const response = await fetch(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            quantity,
            productId,
            locationId,
            sessionDate,
            sessionStartTime,
            email,
            firstName,
            lastName,
            phoneNumber,
            postalCode,
        }),
    });

    if (!response.ok) {
        throw new Error("Failed to get client secret");
    }

    return await response.json();
};
