import { fetchAuthSession } from "@aws-amplify/auth";

const API_BASE_URL = process.env.REACT_APP_MEMBERSHIPS_API_BASE_URL;

export const cancelMemberBooking = async ({ bookingId, customerId }) => {
    const path = addParamsToPath(`${API_BASE_URL}/memberships/bookings`, {
        bookingId,
        customerId,
    });
    const jwtToken = await fetchAuthSession().then((authSession) =>
        authSession.tokens.idToken.toString()
    );
    const response = await fetch(path, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
        },
    });

    if (!response.ok) {
        throw new Error("Failed to cancel member booking");
    }

    return response.json();
};

const addParamsToPath = (path, params) => {
    return (
        path +
        "?" +
        Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&")
    );
};
