const MEMBERSHIP_API_URL = process.env.REACT_APP_MEMBERSHIPS_API_BASE_URL;

export const getMembership = async ({ customerId, jwtToken }) => {
    const path = `${MEMBERSHIP_API_URL}/memberships?customerId=${customerId}`;
    const response = await fetch(`${path}`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Failed to get membership");
    }

    return response.json();
};
