import { useCustomerContext } from "../../context/CustomerContext";
import { MemberBookings } from "./membership/MemberBookings";
import { DayPassBookings } from "./day-pass/DayPassBookings";
import { Loading } from "../general/Loading";

export const Bookings = ({ location }) => {
    const { customerData, loading } = useCustomerContext();
    const { subscriptionActive } = customerData;

    if (loading) {
        return <Loading />;
    }

    if (subscriptionActive) {
        return <MemberBookings location={location} />;
    } else {
        return <DayPassBookings location={location} />;
    }
};
