import { Box } from "@chakra-ui/react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../index.css";

const BookingsCalendar = ({ handleDateChange, selectedDate }) => {
    return (
        <Box
            rounded="lg"
            p={6}
            minWidth={{ sm: "480px", base: "390px" }}
            maxWidth={{ base: "480px" }}
            mt={6}
            mb={{ base: 6 }}
            mr={{ base: 0, lg: 6 }}
        >
            <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                minDate={new Date()}
                maxDate={new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)}
            />
        </Box>
    );
};

export default BookingsCalendar;
