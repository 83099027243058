import { useEffect } from "react";

import { Heading, Text, Box, VStack } from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useCheckoutContext } from "../../context/CheckoutContext";

const LOCATION_ID_ADDRESS = {
    "fort-collins": "123 Main St, Fort Collins, CO 80524",
};

const PRODUCT_ID_TO_NAME = {
    "day-pass": "Day Pass",
};

const PRODUCT_ID_TO_PRICE = {
    "day-pass": 40,
};

export const SessionDetails = ({ title, customerDetails }) => {
    const { checkoutData } = useCheckoutContext();
    const { productId, locationId, sessionDate, sessionStartTime } =
        checkoutData;
    const navigate = useNavigate();

    useEffect(() => {
        const isCheckoutComplete = Object.values(checkoutData).every(
            (value) => value !== ""
        );
        if (!isCheckoutComplete) {
            navigate("/");
        }
    }, [checkoutData, navigate]);

    return (
        <Box textAlign="left">
            <Heading
                textAlign={"center"}
                as="h2"
                size="xl"
                paddingInline={4}
                mb={6}
            >
                {title} a{" "}
                <Text as="span" color="red">
                    Redkindle
                </Text>{" "}
                {PRODUCT_ID_TO_NAME[productId]}
            </Heading>

            <Box borderWidth={2} borderRadius="md" p={6}>
                <VStack spacing={6} align="stretch">
                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Price:
                        </Text>{" "}
                        $
                        {PRODUCT_ID_TO_PRICE[productId] *
                            customerDetails.quantity}{" "}
                        + tax
                    </Text>

                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Session date:
                        </Text>{" "}
                        {sessionDateToReadable(sessionDate)}
                    </Text>

                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Session time:
                        </Text>{" "}
                        {formatTimeRange(sessionStartTime)}
                    </Text>

                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Location:
                        </Text>{" "}
                        {LOCATION_ID_ADDRESS[locationId]}
                    </Text>
                </VStack>
            </Box>
        </Box>
    );
};

const formatTimeRange = (startTime) => {
    const momentDate = moment.utc(startTime, "YYYY-MM-DDTHH:mm:ss");
    const start = momentDate.tz("America/Denver");
    const startTimeFormatted = start.format("h:mma");
    const end = momentDate.add(1, "hour").tz("America/Denver");
    const endTimeFormatted = end.format("h:mma");
    return `${startTimeFormatted} - ${endTimeFormatted} MT`;
};

const sessionDateToReadable = (sessionDate) => {
    const splitDate = sessionDate.split("-");
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];
    return `${month}/${day}/${year}`;
};
