import moment from "moment-timezone";

export const formatTime = (hour) => {
    const ampm = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:00${ampm}`;
};

export const formatSessionStartTimeUtc = (sessionStartTimeUtc) => {
    const sessionStartTime = moment.tz(sessionStartTimeUtc, "America/Denver");
    return sessionStartTime.format("MMMM D, YYYY [at] h:mma");
};

export const formatSessionDate = (sessionDate) => {
    return moment(sessionDate).format("MMMM D, YYYY");
};

export const startTimeInFuture = ({ startTime }) => {
    return moment(startTime).isAfter(moment());
};
