const CUSTOMER_API_URL = process.env.REACT_APP_CUSTOMERS_API_BASE_URL;

export const getOrCreateCustomer = async ({ userData }) => {
    const { email, firstName, lastName, phoneNumber, postalCode } = userData;
    const baseUrl = `${CUSTOMER_API_URL}/get-or-create`;
    const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            firstName,
            lastName,
            phoneNumber,
            postalCode,
        }),
    });

    if (!response.ok) {
        throw new Error("Failed to create customer");
    }

    return response.json();
};
