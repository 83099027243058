import { Box, Text, Badge, Button } from "@chakra-ui/react";
import { formatTime } from "../../../utils/date";

import fire1 from "../../../assets/fire1.png";
import fire2 from "../../../assets/fire2.png";
import iki3 from "../../../assets/iki3.png";
import iki4 from "../../../assets/iki4.png";

const STOVE_IMAGES = [iki4, fire1, iki3, fire2];

export const MemberSession = ({
    slot,
    handleSelectSession,
    customerId,
    setCancelConfirmationModalOpen,
    setSessionToCancel,
    index,
}) => {
    const customerAlreadyBooked = customerAlreadyBookedSession(
        customerId,
        slot
    );
    const customerAlreadyWaitlisted = customerAlreadyWaitlistedSession(
        customerId,
        slot
    );
    return (
        <Box
            key={`${slot.startHour}-${slot.endHour}`}
            p={4}
            bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
            borderWidth="4px"
            borderRadius="2xl"
            borderColor="gray.200"
            minHeight="140px"
            shadow="lg"
            cursor={
                (slot.status === "AVAILABLE" || slot.status === "WAITLIST") &&
                !customerAlreadyBooked &&
                !customerAlreadyWaitlisted
                    ? "pointer"
                    : "default"
            }
            backgroundImage={`url(${
                STOVE_IMAGES[index % STOVE_IMAGES.length]
            })`}
            backgroundSize="cover"
            backgroundPosition="center"
            onClick={() => {
                !customerAlreadyBooked &&
                    !customerAlreadyWaitlisted &&
                    handleSelectSession(slot);
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text fontWeight="bold" fontSize="md">
                    {formatTime(slot.startHour)} - {formatTime(slot.endHour)}
                </Text>
                {customerAlreadyBooked && (
                    <Box>
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                setCancelConfirmationModalOpen(true);
                                setSessionToCancel(slot);
                            }}
                        >
                            Cancel Booking
                        </Button>
                    </Box>
                )}
                {customerAlreadyWaitlisted && (
                    <Box>
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                setCancelConfirmationModalOpen(true);
                                setSessionToCancel(slot);
                            }}
                        >
                            Leave Waitlist
                        </Button>
                    </Box>
                )}
            </Box>
            <BadgeComponent
                customerAlreadyBooked={customerAlreadyBooked}
                customerAlreadyWaitlisted={customerAlreadyWaitlisted}
                slot={slot}
            />
            {slot.spotsRemaining !== 0 && (
                <Text>{slot.spotsRemaining} left</Text>
            )}
            {slot.spotsRemaining === 0 &&
                slot.waitlistSpotsRemaining > 0 &&
                !customerAlreadyWaitlisted &&
                !customerAlreadyBooked && (
                    <Text>
                        {slot.waitlistSpotsRemaining} waitlist spots left
                    </Text>
                )}
            {customerAlreadyWaitlisted && (
                <Text>
                    Waitlist Position:{" "}
                    <strong>{getWaitlistPosition(customerId, slot)}</strong>
                </Text>
            )}
        </Box>
    );
};

const BadgeComponent = ({
    customerAlreadyBooked,
    customerAlreadyWaitlisted,
    slot,
}) => {
    let badgeColor = "green";
    let badgeText = "Available";

    if (customerAlreadyBooked) {
        badgeColor = "blue";
        badgeText = "Booked";
        return (
            <Badge colorScheme={badgeColor} my={2} fontSize="sm">
                {badgeText}
            </Badge>
        );
    }

    if (slot.spotsRemaining === 0) {
        badgeColor = "yellow";
        badgeText = "Waitlist Available";

        if (customerAlreadyWaitlisted) {
            badgeColor = "purple";
            badgeText = "Waitlisted";
        }
    }

    return (
        <Badge colorScheme={badgeColor} my={2} fontSize="sm">
            {badgeText}
        </Badge>
    );
};

const customerAlreadyBookedSession = (customerId, session) => {
    return session.confirmedSet.some(
        (confirmedSetItem) => confirmedSetItem.customerId === customerId
    );
};

const customerAlreadyWaitlistedSession = (customerId, session) => {
    if (
        session.waitlistSet.some(
            (waitlistSetItem) => waitlistSetItem.customerId === customerId
        )
    ) {
        return true;
    }
    return false;
};

const getWaitlistPosition = (customerId, session) => {
    const customerWaitlistMetadata = session.waitlistSet.find(
        (waitlistSetItem) => waitlistSetItem.customerId === customerId
    );
    return customerWaitlistMetadata.waitlistPosition;
};
