import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
} from "@chakra-ui/react";

import { formatTime, formatSessionDate } from "../../../utils/date";
import { Loading } from "../../general/Loading";
import { useCustomerContext } from "../../../context/CustomerContext";

export const MemberCancelBookingModal = ({
    isOpen,
    onClose,
    sessionToCancel,
    handleCancelBooking,
    sessions,
    handleSessionsChange,
    bookingCancelled,
    memberhipBookingLoading,
}) => {
    const { customerData, loading: customerLoading } = useCustomerContext();
    const { customerId } = customerData;

    if (customerLoading) {
        return <Loading height="18vh" width="600px" />;
    }

    const customerWaitlisted = customerWaitlistedSession({
        customerId,
        sessionToCancel,
    });

    const handleCancelBookingClick = async () => {
        const booking = getBookingFromSessionToCancel({
            customerId,
            sessionToCancel,
        });
        const response = await handleCancelBooking();
        if (response.success) {
            const sessionIndex = sessions.findIndex(
                (session) =>
                    session.startHour === sessionToCancel.startHour &&
                    session.endHour === sessionToCancel.endHour
            );
            const updatedSessions = updateSessionsAfterCancellation({
                sessions,
                sessionIndex,
                booking,
                customerWaitlisted,
            });

            handleSessionsChange({ optimisticSessions: updatedSessions });
        }
    };

    if (memberhipBookingLoading) {
        return (
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
                <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(10px)" />
                <ModalContent bg="gray.800" borderRadius="md" boxShadow="lg">
                    <ModalHeader
                        textAlign="center"
                        fontSize="2xl"
                        fontWeight="bold"
                        color="blue.200"
                        py={6}
                    >
                        {customerWaitlisted
                            ? "Leaving Waitlist"
                            : "Cancelling Booking"}
                    </ModalHeader>
                    <ModalBody>
                        <Loading height="18vh" width="600px" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(10px)" />
            <ModalContent bg="gray.800" borderRadius="md" boxShadow="lg">
                <ModalHeader
                    textAlign="center"
                    fontSize="2xl"
                    fontWeight="bold"
                    color="blue.200"
                    py={6}
                >
                    {bookingCancelled
                        ? "Booking Cancelled"
                        : customerWaitlisted
                        ? "Leave Waitlist"
                        : "Confirm Cancellation"}
                </ModalHeader>
                <ModalBody>
                    {sessionToCancel && !bookingCancelled && (
                        <>
                            <Text
                                textAlign="center"
                                fontSize="lg"
                                color="white"
                                mb={4}
                            >
                                Are you sure you want to{" "}
                                {customerWaitlisted
                                    ? "leave the waitlist for the "
                                    : "cancel the "}
                                <strong style={{ color: "orange.300" }}>
                                    {formatTime(sessionToCancel.startHour)} -{" "}
                                    {formatTime(sessionToCancel.endHour)}
                                </strong>{" "}
                                session on{" "}
                                <strong style={{ color: "orange.300" }}>
                                    {formatSessionDate(sessionToCancel.date)}
                                </strong>
                                ?
                            </Text>
                        </>
                    )}
                    {bookingCancelled && (
                        <Text
                            textAlign="center"
                            fontSize="lg"
                            color="white"
                            mb={4}
                        >
                            {customerWaitlisted
                                ? "You have been removed from the waitlist."
                                : "Your booking has been cancelled."}
                        </Text>
                    )}
                </ModalBody>
                <ModalFooter justifyContent="center" py={6}>
                    {!bookingCancelled && (
                        <Button
                            colorScheme="blue"
                            onClick={handleCancelBookingClick}
                            mr={4}
                            px={8}
                            py={3}
                            fontSize="md"
                            fontWeight="semibold"
                        >
                            {customerWaitlisted ? "Leave" : "Confirm"}
                        </Button>
                    )}
                    <Button
                        variant="outline"
                        onClick={onClose}
                        px={8}
                        py={3}
                        fontSize="md"
                        fontWeight="semibold"
                        color="white"
                        borderColor="white"
                        _hover={{ bg: "whiteAlpha.200" }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const getBookingFromSessionToCancel = ({ sessionToCancel, customerId }) => {
    const confirmedBooking = sessionToCancel.confirmedSet.find(
        (booking) => booking.customerId === customerId
    );
    if (confirmedBooking) {
        return confirmedBooking;
    }
    const waitlistBooking = sessionToCancel.waitlistSet.find(
        (booking) => booking.customerId === customerId
    );
    if (waitlistBooking) {
        return waitlistBooking;
    }
    return null;
};

const updateSessionsAfterCancellation = ({
    sessions,
    sessionIndex,
    booking,
    customerWaitlisted,
}) => {
    let updatedSessions = [...sessions];
    const session = updatedSessions[sessionIndex];

    if (customerWaitlisted) {
        updatedSessions = removeBookingFromWaitlist({
            updatedSessions,
            sessionIndex,
            session,
            booking,
        });
    } else {
        const existingWaitlistSet = session.waitlistSet;
        if (existingWaitlistSet.length > 0) {
            const firstInLine = existingWaitlistSet.find(
                (waitlistBooking) => waitlistBooking.waitlistPosition === 1
            );
            if (firstInLine) {
                updatedSessions = handleConfirmedCancelWithWaitlist({
                    updatedSessions,
                    sessionIndex,
                    session,
                    booking,
                    firstInLine,
                    existingWaitlistSet,
                });
            }
        } else {
            updatedSessions = removeBookingFromConfirmed({
                updatedSessions,
                sessionIndex,
                session,
                booking,
            });
        }
    }

    return updatedSessions;
};

const handleConfirmedCancelWithWaitlist = ({
    updatedSessions,
    sessionIndex,
    session,
    booking,
    firstInLine,
    existingWaitlistSet,
}) => {
    const updateSessionsClone = [...updatedSessions];
    updateSessionsClone[sessionIndex] = {
        ...session,
        confirmedSet: [
            ...session.confirmedSet.filter(
                (confirmedBooking) =>
                    confirmedBooking.bookingId !== booking.bookingId
            ),
            firstInLine,
        ],
        waitlistSet: existingWaitlistSet.filter(
            (waitlistBooking) =>
                waitlistBooking.bookingId !== firstInLine.bookingId
        ),
        waitlistSpotsRemaining: session.waitlistSpotsRemaining + 1,
    };
    return updateSessionsClone;
};

const removeBookingFromWaitlist = ({
    updatedSessions,
    sessionIndex,
    session,
    booking,
}) => {
    const updateSessionsClone = [...updatedSessions];
    updateSessionsClone[sessionIndex] = {
        ...session,
        waitlistSet: session.waitlistSet.filter(
            (waitlistBooking) => waitlistBooking.bookingId !== booking.bookingId
        ),
        waitlistSpotsRemaining: session.waitlistSpotsRemaining + 1,
    };
    return updateSessionsClone;
};

const removeBookingFromConfirmed = ({
    updatedSessions,
    sessionIndex,
    session,
    booking,
}) => {
    const updateSessionsClone = [...updatedSessions];
    updateSessionsClone[sessionIndex] = {
        ...session,
        confirmedSet: session.confirmedSet.filter(
            (confirmedBooking) =>
                confirmedBooking.bookingId !== booking.bookingId
        ),
        spotsRemaining: session.spotsRemaining + 1,
    };
    return updateSessionsClone;
};

const customerWaitlistedSession = ({ customerId, sessionToCancel }) => {
    if (sessionToCancel === null) {
        return false;
    }
    return sessionToCancel.waitlistSet.some(
        (waitlistSetItem) => waitlistSetItem.customerId === customerId
    );
};
