import { useState } from "react";
import {
    Box,
    Avatar,
    Flex,
    Button,
    Image,
    Link as ChakraLink,
    useColorModeValue,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    MenuDivider,
    MenuGroup,
    DrawerContent,
    DrawerCloseButton,
    VStack,
    useBreakpointValue,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";

import hotspotLogo from "../../assets/hotspotlogo.png";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { signOut } from "@aws-amplify/auth";

function Navbar() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const textColor = useColorModeValue("white", "white");
    const accentColor = useColorModeValue("maroon.500", "maroon.300");

    const isMobile = useBreakpointValue({ base: true, md: false });
    const { authStatus, user } = useAuthenticator((context) => [
        context.authStatus,
        context.user,
    ]);

    if (authStatus === "loading") {
        return null;
    }

    const handleLogout = async () => {
        try {
            await signOut();
            setIsOpen(false);
            navigate("/");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };
    // Full navbar for development
    return (
        <Box
            bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
            px={4}
            shadow="md"
            position="relative"
        >
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                    <ChakraLink
                        as={RouterLink}
                        to="/"
                        display="flex"
                        alignItems="center"
                    >
                        <Image
                            src={hotspotLogo}
                            alt="Hotspot Logo"
                            h="40px"
                            mr={2}
                        />
                    </ChakraLink>
                </Flex>

                <Flex alignItems="center" gap={2}>
                    <AccountSection
                        authStatus={authStatus}
                        textColor={textColor}
                        accentColor={accentColor}
                        handleLogout={handleLogout}
                        user={user}
                    />
                    <IconButton
                        icon={<HamburgerIcon />}
                        variant="outline"
                        color={textColor}
                        _hover={{ color: accentColor }}
                        onClick={() => setIsOpen(true)}
                    />
                </Flex>

                <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={() => setIsOpen(false)}
                    size={isMobile ? "full" : "md"}
                >
                    <DrawerOverlay />
                    <DrawerContent
                        bg="linear-gradient(225deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
                        color="white"
                    >
                        <DrawerCloseButton />

                        <DrawerBody
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <VStack spacing={8} align="stretch" width="100%">
                                <Button
                                    as={RouterLink}
                                    to="/bookings/fort-collins"
                                    colorScheme="blackAlpha"
                                    size="lg"
                                    height="60px"
                                    fontSize="3xl"
                                    onClick={() => setIsOpen(false)}
                                    _hover={{ bg: "whiteAlpha.200" }}
                                    color="white"
                                    justifyContent="flex-end"
                                >
                                    Fort Collins
                                </Button>

                                <Button
                                    as={RouterLink}
                                    to="/memberships"
                                    colorScheme="blackAlpha"
                                    size="lg"
                                    height="60px"
                                    fontSize="3xl"
                                    onClick={() => setIsOpen(false)}
                                    _hover={{ bg: "whiteAlpha.200" }}
                                    color="white"
                                    justifyContent="flex-end"
                                >
                                    Memberships
                                </Button>

                                <Button
                                    as={RouterLink}
                                    to="/about"
                                    colorScheme="blackAlpha"
                                    size="lg"
                                    height="60px"
                                    fontSize="3xl"
                                    onClick={() => setIsOpen(false)}
                                    _hover={{ bg: "whiteAlpha.200" }}
                                    color="white"
                                    justifyContent="flex-end"
                                >
                                    About
                                </Button>
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Flex>
        </Box>
    );
}

export default Navbar;

const AccountSection = ({ authStatus, textColor, handleLogout, user }) => {
    if (authStatus === "authenticated" && user) {
        return (
            <Flex alignItems="center" gap={4}>
                <Menu>
                    <MenuButton
                        as={Button}
                        variant="ghost"
                        display={{ base: "none", md: "flex" }}
                        rightIcon={<ChevronDownIcon />}
                        px={2}
                        py={1}
                        _hover={{ bg: "whiteAlpha.200" }}
                    >
                        <Flex alignItems="center" gap={3}>
                            <Avatar size="sm" bg="maroon.600" />
                        </Flex>
                    </MenuButton>
                    <MenuList bg="gray.900" borderColor="whiteAlpha.200">
                        <MenuGroup
                            title={`Hello, ${user.username}`}
                            color="white"
                            fontWeight="bold"
                            fontSize="md"
                            textAlign="left"
                        />
                        <MenuDivider borderColor="whiteAlpha.400" />
                        <MenuItem
                            as={RouterLink}
                            to="/account"
                            _hover={{ bg: "whiteAlpha.200" }}
                            color="white"
                        >
                            <Text ml={1}>Account</Text>
                        </MenuItem>
                        <MenuDivider borderColor="whiteAlpha.400" />
                        <MenuItem
                            onClick={handleLogout}
                            _hover={{ bg: "whiteAlpha.200" }}
                            color="white"
                        >
                            <Text ml={1}>Log Out</Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        );
    }

    return (
        <Flex
            alignItems="center"
            gap={4}
            display={{ base: "none", md: "flex" }}
        >
            <Menu>
                <MenuButton
                    as={Button}
                    variant="ghost"
                    display={{ base: "none", md: "flex" }}
                    rightIcon={<ChevronDownIcon />}
                    px={2}
                    py={1}
                    _hover={{ bg: "whiteAlpha.200" }}
                >
                    <Flex alignItems="center" gap={3}>
                        <Avatar size="sm" />
                    </Flex>
                </MenuButton>
                <MenuList bg="gray.900" borderColor="whiteAlpha.200">
                    <MenuItem
                        as={RouterLink}
                        to="/login"
                        _hover={{ bg: "whiteAlpha.200" }}
                        color="white"
                    >
                        Log In
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};
