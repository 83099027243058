import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Image,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import Kirill from "../assets/kirill.jpg";
import Asuka from "../assets/asuka.jpg";
import AsukaAndKirill from "../assets/asuka-and-kirill.jpg";

// ... imports remain the same ...

function AboutUs() {
  const boxBg = useColorModeValue(
    "rgba(0, 0, 30, 1)",
    "rgba(0, 0, 30, 1)"
  );
  const textColor = useColorModeValue("gray.100", "gray.100");
  const borderColor = useColorModeValue(
    "rgba(255, 255, 255, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );

  return (
    <Container maxW="container.xl" py={12}>
      {/* Hero Section */}
      <Box
        position="relative"
        borderRadius="2xl"
        overflow="hidden"
        mb={16}
        bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
        p={8}
      >
        <VStack spacing={8} textAlign="center">
          <Heading
            size="2xl"
            color={textColor}
            textShadow="0 2px 4px rgba(0,0,0,0.3)"
          >
            About Fenyx
          </Heading>
          <Text
            fontSize="xl"
            color="gray.300"
            maxW="800px"
            textShadow="0 1px 2px rgba(0,0,0,0.2)"
          >
            Fenyx was born from a unique blend of Russian and Japanese
            bathing traditions, founded by a couple who discovered their shared
            passion for healing through heat therapy.
          </Text>
        </VStack>
      </Box>

      {/* Founders Section */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={16}>
        <Box
          bg={boxBg}
          p={8}
          borderRadius="lg"
          borderWidth="1px"
          borderColor={borderColor}
          transition="all 0.4s ease"
          _hover={{
            transform: "translateY(-5px)",
            borderColor: "maroon.400",
            boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
          }}
        >
          <VStack align="start" spacing={4}>
            <Image
              borderRadius="lg"
              src={Kirill}
              alt="Kirill"
              width="100%"
              fallbackSrc="https://via.placeholder.com/300"
            />
            <Heading size="md" color={textColor}>
              Kirill
            </Heading>
            <Text color="gray.300">
              Growing up in Massachusetts, Kirill was introduced to banya
              culture in his teens through Russian mentors who shared their deep
              knowledge of traditional heat therapy. These steam room sessions
              became more than just a refuge after cold days with friends - they
              grew into a passion that led him to explore sauna traditions
              around the world. His personal journey with health challenges
              deepened his appreciation for the profound benefits of heat
              therapy, particularly through the time-honored practices of banya.
              This experience, combined with his global perspective on sauna
              culture, sparked his mission to make these healing traditions more
              accessible to others.
            </Text>
          </VStack>
        </Box>

        <Box
          bg={boxBg}
          p={8}
          borderRadius="lg"
          borderWidth="1px"
          borderColor={borderColor}
          transition="all 0.4s ease"
          _hover={{
            transform: "translateY(-5px)",
            borderColor: "maroon.400",
            boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
          }}
        >
          <VStack align="start" spacing={4}>
            <Image
              borderRadius="lg"
              src={Asuka}
              alt="Asuka"
              width="100%"
              fallbackSrc="https://via.placeholder.com/300"
            />
            <Heading size="md" color={textColor}>
              Asuka
            </Heading>
            <Text color="gray.300">
              Raised in a cabin deep in the Colorado woods with a traditional
              Japanese upbringing, Asuka's connection to heat therapy began with
              the sauna at her family home. Her understanding of wellness
              traditions deepened during a formative year spent in Japan, where
              she immersed herself in the country's onsen culture. Living with
              autoimmune conditions that significantly impact her daily life,
              Asuka discovered how the combination of heat therapy and community
              can provide profound rejuvenation. Her personal journey with
              chronic illness, coupled with her unique blend of Japanese
              cultural heritage and American upbringing, brings an invaluable
              perspective to Fenyx's mission of making heat therapy
              accessible and communal.
            </Text>
          </VStack>
        </Box>
      </SimpleGrid>

      {/* Mission Section */}
      <Box
        position="relative"
        borderRadius="2xl"
        overflow="hidden"
        mb={16}
        bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
        p={8}
      >
        <VStack spacing={8}>
          <Heading
            size="xl"
            color={textColor}
            textShadow="0 2px 4px rgba(0,0,0,0.3)"
          >
            Our Mission
          </Heading>
          <Text color="gray.300" textAlign="center">
            At Fenyx, we believe in the transformative power of heat therapy
            traditions. Drawing from our personal experiences with health
            challenges and our rich cultural backgrounds in Russian banya and
            Japanese onsen, we've made it our mission to help others discover
            these powerful healing practices. We understand firsthand how proper
            heat therapy can improve sleep, aid in physical recovery, and
            support mental wellness.
          </Text>
        </VStack>
      </Box>

      {/* Couple Image */}
      <Box display="flex" justifyContent="center" width="100%" mb={16}>
        <Image
          width={{ base: "100%", md: "70%", lg: "60%" }}
          objectFit="cover"
          borderRadius="lg"
          src={AsukaAndKirill}
          alt="Asuka and Kirill"
          fallbackSrc="https://via.placeholder.com/300"
          boxShadow="xl"
          transition="transform 0.3s ease"
          _hover={{
            transform: "scale(1.02)",
          }}
        />
      </Box>

      {/* Vision Section */}
      <Box
        position="relative"
        borderRadius="2xl"
        overflow="hidden"
        bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
        p={8}
      >
        <VStack spacing={8}>
          <Heading
            size="xl"
            color={textColor}
            textShadow="0 2px 4px rgba(0,0,0,0.3)"
          >
            Our Vision
          </Heading>
          <Text color="gray.300" textAlign="center">
            We're creating more than just a booking platform - we're building a
            community that connects people with authentic heat therapy
            experiences. Whether you're seeking relief from physical discomfort,
            looking to improve your sleep quality, or simply need a space for
            mental restoration, Fenyx is here to guide you to the perfect
            venue. By bridging Eastern and Western healing traditions, we're
            making these time-tested wellness practices more accessible to
            everyone.
          </Text>
        </VStack>
      </Box>
    </Container>
  );
}

export default AboutUs;