const API_BASE_URL = process.env.REACT_APP_SUBSCRIPTIONS_API_BASE_URL;

export const createMembershipSubscription = async ({
    productId,
    customerDetails,
    customerId,
}) => {
    const { email, firstName, lastName, phoneNumber } = customerDetails;
    const path = `${API_BASE_URL}/subscriptions`;
    const response = await fetch(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            productId,
            email,
            firstName,
            lastName,
            phoneNumber,
            customerId,
        }),
    });

    if (!response.ok) {
        throw new Error("Failed to get client secret");
    }
    return await response.json();
};
