import { Box, Text } from "@chakra-ui/react";

const InfoSection = ({ location }) => {
  return (
    <Box
      bg="linear-gradient(135deg, rgba(0, 0, 30, .7) 0%, rgba(55, 1, 25, .7) 100%)"
      color="white"
      paddingTop={9}
      p={12}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      maxWidth={"500px"}
      minWidth={"50%"}
      height={{ base: "auto", lg: "400px" }}
    >
      <Text fontSize="3xl" fontWeight="bold" mb={4}>
        {location} Session
      </Text>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Mon-Sun
      </Text>
      <Text fontSize="lg" mb={4}>
        8am-close, Lasts 1 hour
      </Text>
      <Text fontSize="lg">
        Explore our cold plunges and saunas. Includes admission and access to
        amenities. Day Pass is valid for the date and time you booked. Please
        check in at the time you reserved.
      </Text>
    </Box>
  );
};

export default InfoSection;
