import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Spinner, Center } from "@chakra-ui/react";

import { FortCollinsBookings } from "./pages/FortCollinsBookings";
import Navbar from "./components/layout/Navbar";
import Home from "./pages/Home";
import Login from "./pages/Login";
import UserAccount from "./pages/UserAccount";
import AboutUs from "./pages/AboutUs";
import Memberships from "./pages/Memberships";
import { Membership } from "./pages/checkout/Membership";
import { DayPass } from "./pages/checkout/DayPass";

import { Success } from "./pages/Success";
function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) {
        return (
            <Center h="100vh">
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            </Center>
        );
    }

    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route
                        path="/bookings/fort-collins"
                        element={<FortCollinsBookings />}
                    />
                    <Route path="/memberships" element={<Memberships />} />
                    <Route path="/account" element={<UserAccount />} />
                    <Route
                        path="/checkout/membership"
                        element={<Membership />}
                    />
                    <Route path="/checkout/day-pass" element={<DayPass />} />
                    <Route path="/day-pass/success" element={<Success />} />
                    <Route path="/membership/success" element={<Success />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
