import { Heading, Text, Box, VStack } from "@chakra-ui/react";

const PRODUCT_ID_TO_NAME = {
    "four-week": "Four Week Membership",
    "twelve-week": "Twelve Week Membership",
};

const PRODUCT_ID_TO_PRICE = {
    "four-week": 39.99,
    "twelve-week": 34.99,
};

const MEMBERSHIP_WEEKS = {
    "four-week": 4,
    "twelve-week": 12,
};

export const MembershipDetails = ({ productId }) => {
    return (
        <Box textAlign="left">
            <Heading
                textAlign={"center"}
                as="h2"
                size="xl"
                paddingInline={4}
                mb={6}
            >
                Purchase a{" "}
                <Text as="span" color="red">
                    Redkindle
                </Text>{" "}
                {PRODUCT_ID_TO_NAME[productId]}
            </Heading>

            <Box borderWidth={2} borderRadius="md" p={6}>
                <VStack spacing={6} align="stretch">
                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Price:
                        </Text>{" "}
                        <br />${PRODUCT_ID_TO_PRICE[productId]} + tax
                    </Text>
                </VStack>
            </Box>

            <Box borderWidth={2} borderRadius="md" p={6}>
                <VStack spacing={6} align="stretch">
                    <Text fontSize="2xl">
                        <Text as="span" fontWeight="bold">
                            Membership renewal date:
                        </Text>{" "}
                        <br />
                        {getMembershipEndDate(productId)}
                    </Text>
                </VStack>
            </Box>
        </Box>
    );
};

const getMembershipEndDate = (productId) => {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + MEMBERSHIP_WEEKS[productId] * 7);
    return endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
};
