import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { Container, Box } from "@chakra-ui/react";

import { LOCATION_TO_ID } from "../../constants";
import { getSessions } from "../../api/getSessions";
import Calendar from "./Calendar";
import { DayPassBookingListModal } from "./day-pass/DayPassBookingListModal";
import { DayPassConfirmationModal } from "./day-pass/DayPassConfirmationModal";
import { MembershipBookingListModal } from "./membership/MembershipBookingListModal";
import { MemberConfirmationModal } from "./membership/MembershipConfirmationModal";
import { MemberCancelBookingModal } from "./membership/MemberCancelBookingModal";
import InfoSection from "./InfoSection";

import { BOOKING_TYPE } from "../../constants";

export const BaseBookings = ({
    location,
    dateProps,
    sessionProps,
    bookingProps,
    cancelProps,
}) => {
    const { selectedDate, setSelectedDate } = dateProps;
    const {
        sessionDate,
        selectedSession,
        sessionStartTime,
        handleSelectSession,
    } = sessionProps;
    const {
        bookingType,
        handleBookingAttempt,
        confirmationModalOpen,
        setConfirmationModalOpen,
        bookingConfirmed,
        setBookingConfirmed,
        memberhipBookingLoading,
    } = bookingProps;
    const {
        handleCancelBooking,
        bookingCancelled,
        setBookingCancelled,
        cancelConfirmationModalOpen,
        setCancelConfirmationModalOpen,
        setSessionToCancel,
        sessionToCancel,
    } = cancelProps;
    const [isBookingListModalOpen, setIsBookingListModalOpen] = useState(false);

    const {
        data: sessions,
        isLoading: sessionsLoading,
        isError: sessionsError,
    } = useQuery(
        ["sessions", sessionDate],
        () =>
            getSessions({
                sessionDate: sessionDate,
                location: LOCATION_TO_ID[location],
            }),
        {
            enabled: sessionDate !== null,
        }
    );
    const [localSessions, setLocalSessions] = useState([]);

    useEffect(() => {
        setLocalSessions(sessions?.sessions || []);
    }, [sessions]);

    const handleSessionsChange = ({ optimisticSessions }) => {
        setLocalSessions(optimisticSessions);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setIsBookingListModalOpen(true);
    };

    const handleBookingListModalClose = () => {
        setIsBookingListModalOpen(false);
    };

    return (
        <Box position="relative" minHeight="100vh" bg="black">
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                width="100vw"
                height={{ base: "130vh", md: "100vh" }}
            >
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <source src="/fire-smolder.mp4" type="video/mp4" />
                </video>
            </Box>
            <Container
                maxW="container.xl"
                position="relative"
                py={8}
                paddingRight={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {/* Calendar and Info Section */}
                <Box
                    display="flex"
                    flexDirection={{ base: "column", lg: "row" }}
                    alignItems={{ base: "center" }}
                    justifyContent={{ base: "center" }}
                    width="100%"
                    padding={12}
                >
                    {/* Calendar Section */}
                    <Calendar
                        handleDateChange={handleDateChange}
                        selectedDate={selectedDate}
                    />

                    {/* Info Section */}
                    <InfoSection location={location} />
                </Box>

                {bookingType === BOOKING_TYPE.DAY_PASS && (
                    <>
                        <DayPassBookingListModal
                            isOpen={isBookingListModalOpen}
                            onClose={handleBookingListModalClose}
                            sessionDate={sessionDate}
                            location={location}
                            handleSelectSession={handleSelectSession}
                        />
                        <DayPassConfirmationModal
                            isOpen={confirmationModalOpen}
                            onClose={() => setConfirmationModalOpen(false)}
                            selectedSession={selectedSession}
                            handleBookingAttempt={handleBookingAttempt}
                            sessionDate={sessionDate}
                        />
                    </>
                )}

                {bookingType === BOOKING_TYPE.MEMBER && (
                    <>
                        <MembershipBookingListModal
                            isOpen={isBookingListModalOpen}
                            onClose={handleBookingListModalClose}
                            sessionDate={sessionDate}
                            location={location}
                            handleSelectSession={handleSelectSession}
                            setCancelConfirmationModalOpen={
                                setCancelConfirmationModalOpen
                            }
                            setSessionToCancel={setSessionToCancel}
                            localSessions={localSessions}
                            sessionsLoading={sessionsLoading}
                            sessionsError={sessionsError}
                            handleSessionsChange={handleSessionsChange}
                        />
                        <MemberConfirmationModal
                            isOpen={confirmationModalOpen}
                            onClose={() => {
                                setConfirmationModalOpen(false);
                                setBookingConfirmed(false);
                            }}
                            selectedSession={selectedSession}
                            handleBookingAttempt={handleBookingAttempt}
                            sessionStartTime={sessionStartTime}
                            bookingConfirmed={bookingConfirmed}
                            memberhipBookingLoading={memberhipBookingLoading}
                            handleSessionsChange={handleSessionsChange}
                            sessions={localSessions}
                            sessionDate={sessionDate}
                        />
                        <MemberCancelBookingModal
                            isOpen={cancelConfirmationModalOpen}
                            onClose={() => {
                                setCancelConfirmationModalOpen(false);
                                setBookingCancelled(false);
                            }}
                            handleCancelBooking={handleCancelBooking}
                            bookingCancelled={bookingCancelled}
                            memberhipBookingLoading={memberhipBookingLoading}
                            setBookingCancelled={setBookingCancelled}
                            sessionToCancel={sessionToCancel}
                            handleSessionsChange={handleSessionsChange}
                            sessions={localSessions}
                        />
                    </>
                )}
            </Container>
        </Box>
    );
};
