import React from "react";
import { Box, Heading, Text, Button, Grid } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

import { Loading } from "../components/general/Loading";
import { useCheckoutContext } from "../context/CheckoutContext";
import { useCustomerContext } from "../context/CustomerContext";

const MEMBERSHIP_OPTIONS = [
    {
        id: "four-week",
        name: "Four Week Membership",
        description:
            "Buy a four week membership, access to all sessions, and discounts on all products and services. Billed every 28 days.",
        price: 39.99,
        duration: "four-week",
    },
    {
        id: "twelve-week",
        name: "Twelve Week Membership",
        description:
            "Buy a twelve week membership, access to all sessions, and discounts on all products and services. Billed every 84 days.",
        price: 34.99,
        duration: "twelve-week",
    },
];

const Memberships = () => {
    const { customerData, loading } = useCustomerContext();
    const { subscriptionActive } = customerData;

    if (loading) {
        return <Loading />;
    }

    return (
        <Box
            maxW="7xl"
            mx="auto"
            px={4}
            py={8}
            display="flex"
            justifyContent="center"
        >
            <Grid
                templateColumns={{ base: "1fr" }}
                maxWidth={{ md: "700px" }}
                gap={8}
            >
                {subscriptionActive && (
                    <Box
                        p={6}
                        bg="green.700"
                        borderRadius="lg"
                        textAlign="center"
                        marginLeft={4}
                        marginRight={4}
                    >
                        <Heading as="h3" size="lg" mb={2}>
                            Your Membership is Active
                        </Heading>
                        <Text fontSize="md" mb={4}>
                            You have full access to all sessions and member
                            discounts.
                        </Text>
                        <ChakraLink
                            as={RouterLink}
                            to="/account"
                            color="white"
                            textDecoration="underline"
                            _hover={{ color: "gray.200" }}
                        >
                            Visit your account page for membership details →
                        </ChakraLink>
                    </Box>
                )}
                {MEMBERSHIP_OPTIONS.map((membership) => (
                    <Box
                        marginLeft={4}
                        marginRight={4}
                        key={membership.id}
                        bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
                        borderWidth={1}
                        borderRadius="md"
                        borderColor="gray.300"
                        overflow="hidden"
                        p={4}
                        boxShadow="sm"
                    >
                        <Box p={4}>
                            <Heading as="h3" size="lg" mb={2}>
                                {membership.name}
                            </Heading>
                            <Text fontSize="md" lineHeight="tall" mb={4}>
                                {membership.description}
                            </Text>
                        </Box>
                        <Box p={4}>
                            <Text fontSize="xl" fontWeight="medium">
                                ${membership.price.toFixed(2)} per week
                            </Text>
                        </Box>
                        <MembershipButton
                            membership={membership}
                            subscriptionActive={subscriptionActive}
                        />
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};

const MembershipButton = ({ membership, subscriptionActive }) => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const { updateMembershipData } = useCheckoutContext();
    const navigate = useNavigate();
    const handleClick = () => {
        updateMembershipData({ productId: membership.id });
        navigate("/checkout/membership");
    };

    if (subscriptionActive) {
        return;
    }

    if (authStatus === "authenticated") {
        return (
            <Box p={4} display="flex" justifyContent="flex-end">
                <Button
                    onClick={handleClick}
                    size="md"
                    width="30%"
                    minWidth="160px"
                    bg="purple.900"
                    _hover={{ bg: "purple.600" }}
                >
                    {membership.name.split(" ")[0]} Week Pass
                </Button>
            </Box>
        );
    }

    return (
        <Box p={4} display="flex" justifyContent="flex-end">
            <ChakraLink
                as={RouterLink}
                to="/login"
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    size="md"
                    width="30%"
                    minWidth="160px"
                    bg="blue.800"
                    _hover={{ bg: "blue.600" }}
                >
                    Sign in
                </Button>
            </ChakraLink>
        </Box>
    );
};

export default Memberships;
