import { useState } from "react";
import {
    Heading,
    Box,
    VStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    FormControl,
    Button,
} from "@chakra-ui/react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { confirmSessionAvailable } from "../../api/confirmSessionAvailable";
import { useCheckoutContext } from "../../context/CheckoutContext";

export const DayPassPaymentComponent = ({ clientSecret, quantity }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);
    const { checkoutData } = useCheckoutContext();
    const { locationId, sessionDate, sessionStartTime } = checkoutData;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        setIsLoading(true);
        setErrorMessage(null);

        const sessionAvailableResponse = await confirmSessionAvailable({
            bookingData: {
                locationId,
                sessionDate,
                sessionStartTime,
                quantity,
            },
        });

        const { isAvailable, message } = sessionAvailableResponse;

        if (!isAvailable) {
            setErrorMessage(message);
            setIsLoading(false);
            return;
        }

        // Call elements.submit() before stripe.confirmPayment()
        const { error: submitError } = await elements.submit();

        if (submitError) {
            setErrorMessage(submitError.message);
            setIsLoading(false);
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/day-pass/success`,
            },
            clientSecret,
        });

        if (error) {
            setErrorMessage(error.message);
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            setPaymentSucceeded(true);
        }

        setIsLoading(false);
    };

    return (
        <>
            {paymentSucceeded ? (
                <Alert status="success">
                    <AlertIcon />
                    <AlertTitle>Payment Successful!</AlertTitle>
                    <AlertDescription>
                        Thank you for your purchase. Your day pass has been
                        activated.
                    </AlertDescription>
                </Alert>
            ) : (
                <Box borderWidth={1} borderRadius="md" p={4}>
                    <FormControl as="form" onSubmit={handleSubmit}>
                        <VStack spacing={6} align="stretch">
                            <Heading as="h3" size="lg">
                                Payment Details
                            </Heading>
                            <PaymentElement />
                            {errorMessage && (
                                <Alert status="error" mt={4}>
                                    <AlertIcon />
                                    <AlertTitle>Payment Error</AlertTitle>
                                    <AlertDescription>
                                        {errorMessage}
                                    </AlertDescription>
                                </Alert>
                            )}
                            <Button
                                mt={4}
                                colorScheme="blue"
                                size="lg"
                                type="submit"
                                disabled={!stripe || isLoading}
                                isLoading={isLoading}
                            >
                                {isLoading ? "Processing..." : "Pay Now"}
                            </Button>
                        </VStack>
                    </FormControl>
                </Box>
            )}
        </>
    );
};
