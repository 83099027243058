import { useQuery } from "react-query";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Box,
    Spinner,
    Text,
    Alert,
    AlertIcon,
    SimpleGrid,
    Badge,
} from "@chakra-ui/react";

import { LOCATION_TO_ID } from "../../../constants";
import { getSessions } from "../../../api/getSessions";
import { formatTime, formatSessionDate } from "../../../utils/date";

import fire1 from "../../../assets/fire1.png";
import fire2 from "../../../assets/fire2.png";
import iki3 from "../../../assets/iki3.png";
import iki4 from "../../../assets/iki4.png";

const STOVE_IMAGES = [iki4, fire1, iki3, fire2];

export const DayPassBookingListModal = ({
    isOpen,
    onClose,
    sessionDate,
    location,
    handleSelectSession,
}) => {
    const {
        data: sessions,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["sessions", sessionDate],
        () =>
            getSessions({
                sessionDate: sessionDate,
                location: LOCATION_TO_ID[location],
            }),
        {
            enabled: sessionDate !== null,
        }
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" bg="black">
            <ModalOverlay />
            <ModalContent
                bg="black"
                mt={40}
                borderRadius="2xl"
                borderWidth="4px"
                borderColor="white.500"
            >
                <ModalHeader>
                    Purchase a day pass for a session on{" "}
                    {formatSessionDate(sessionDate)}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* Handle Loading State */}
                    {isLoading && (
                        <Box textAlign="center">
                            <Spinner size="xl" />
                            <Text>Loading sessions...</Text>
                        </Box>
                    )}

                    {/* Handle Error State */}
                    {isError && (
                        <Alert status="error">
                            <AlertIcon />
                            {error.message}
                        </Alert>
                    )}

                    {/* Display Sessions */}
                    {sessions && sessions.sessions.length > 0 ? (
                        <SimpleGrid
                            columns={{ base: 1, md: 1 }}
                            spacing={4}
                            paddingTop={2}
                            paddingBottom={4}
                            bg="black"
                        >
                            {sessions.sessions.map((slot, index) => (
                                <Box
                                    key={`${slot.startHour}-${slot.endHour}`}
                                    p={4}
                                    bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
                                    borderWidth="4px"
                                    borderRadius="2xl"
                                    borderColor="gray.200"
                                    minHeight="150px"
                                    shadow="lg"
                                    cursor={
                                        slot.status === "AVAILABLE"
                                            ? "pointer"
                                            : "not-allowed"
                                    }
                                    backgroundImage={`url(${
                                        STOVE_IMAGES[
                                            index % STOVE_IMAGES.length
                                        ]
                                    })`}
                                    backgroundSize="cover"
                                    backgroundPosition="center"
                                    onClick={() => handleSelectSession(slot)}
                                >
                                    <Text fontWeight="bold">
                                        {formatTime(slot.startHour)} -{" "}
                                        {formatTime(slot.endHour)}
                                    </Text>
                                    <Badge
                                        colorScheme={
                                            slot.status === "AVAILABLE"
                                                ? "green"
                                                : "red"
                                        }
                                        my={2}
                                    >
                                        {slot.status === "AVAILABLE"
                                            ? "Available"
                                            : "Full"}
                                    </Badge>
                                    {slot.spotsRemaining !== 0 && (
                                        <Text>{slot.spotsRemaining} left</Text>
                                    )}
                                    {slot.status === "AVAILABLE" && (
                                        <Text> $40 </Text>
                                    )}
                                </Box>
                            ))}
                        </SimpleGrid>
                    ) : (
                        !isLoading && (
                            <Text paddingTop={4} paddingBottom={4}>
                                No sessions available for this date.
                            </Text>
                        )
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
