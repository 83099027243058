const API_BASE_URL = process.env.REACT_APP_BOOKINGS_API_BASE_URL;

export const getSessions = async ({ sessionDate, location }) => {
  const response = await fetch(
    `${API_BASE_URL}/sessions?sessionDate=${sessionDate}&location=${location}`,
    {
      headers: {
        "Content-Type": "application/json",
        Origin: "*",
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch sessions");
  }
  return response.json();
};
