import React, { useEffect, useState } from "react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { MembershipDetails } from "../../components/checkout/MembershipDetails";
import { MembershipPaymentComponent } from "../../components/checkout/MembershipPaymentComponent";
import { MembershipStripeWrapper } from "../../components/checkout/MembershipStripeWrapper";
import { DetailsInputForm } from "../../components/checkout/DetailsInputForm";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { fetchUserAttributes } from "aws-amplify/auth";

const isObjectComplete = (obj) =>
    Object.values(obj).every((value) => value !== "");

export const Membership = () => {
    const navigate = useNavigate();
    const [isDetailsComplete, setIsDetailsComplete] = useState(false);
    const { membershipData } = useCheckoutContext();
    const { productId } = membershipData;

    const [customerDetails, setCustomerDetails] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        postalCode: "",
    });

    useEffect(() => {
        if (productId.length === 0) {
            navigate("/");
        }
    }, [productId, navigate]);

    const handleCustomerDetailsChange = ({ field, value }) => {
        setCustomerDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        const isDetailsComplete = isObjectComplete(customerDetails);
        setIsDetailsComplete(isDetailsComplete);
    };

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const attributes = await fetchUserAttributes();
                setCustomerDetails({
                    email: attributes.email,
                    firstName: attributes.given_name,
                    lastName: attributes.family_name,
                    phoneNumber: attributes.phone_number,
                    postalCode: attributes.address,
                });
            } catch (error) {
                console.log("Error fetching user details:", error);
            }
        };

        fetchUserDetails();
    }, []);

    return (
        <Box
            maxW="1200px"
            mx="auto"
            mt={8}
            p={8}
            bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
            borderRadius="2xl"
        >
            <Grid
                templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                gap={8}
            >
                <GridItem>
                    <MembershipDetails productId={productId} />
                </GridItem>
                <GridItem>
                    {isDetailsComplete ? (
                        <MembershipStripeWrapper
                            customerDetails={customerDetails}
                        >
                            <MembershipPaymentComponent />
                        </MembershipStripeWrapper>
                    ) : (
                        <DetailsInputForm
                            customerDetails={customerDetails}
                            onCustomerDetailsChange={
                                handleCustomerDetailsChange
                            }
                            onSubmit={handleSubmit}
                            type="membership"
                        />
                    )}
                </GridItem>
            </Grid>
        </Box>
    );
};
