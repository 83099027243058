const BOOKINGS_API_BASE_URL = process.env.REACT_APP_BOOKINGS_API_BASE_URL;

export const getCustomerBookings = async ({ customerId, jwtToken }) => {
    const path = `${BOOKINGS_API_BASE_URL}/bookings/customer`;
    const response = await fetch(`${path}?customerId=${customerId}`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Failed to get customer bookings");
    }

    return response.json();
};
