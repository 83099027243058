import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
} from "@chakra-ui/react";

import { formatTime, formatSessionStartTimeUtc } from "../../../utils/date";
import { Loading } from "../../general/Loading";
import { useCustomerContext } from "../../../context/CustomerContext";
import { formatSessionDate } from "../../../utils/date";

export const MemberConfirmationModal = ({
    isOpen,
    onClose,
    selectedSession,
    handleBookingAttempt,
    sessionStartTime,
    bookingConfirmed,
    memberhipBookingLoading,
    sessions,
    sessionDate,
    handleSessionsChange,
}) => {
    const { customerData, loading } = useCustomerContext();
    const customerId = customerData.customerId;
    if (loading) {
        return <Loading height="18vh" width="600px" />;
    }

    if (selectedSession === null) {
        return null;
    }
    const isWaitlist = selectedSession.status === "WAITLIST";

    const handleBookingAttemptClick = async () => {
        const response = await handleBookingAttempt();
        if (response.success) {
            const booking = {};
            booking.bookingId = response.bookingId;
            booking.customerId = customerId;
            if (isWaitlist) {
                const waitlistPosition = response.waitlistPosition;
                booking.waitlistPosition = waitlistPosition;
            }
            const sessionIndex = sessions.findIndex(
                (session) =>
                    session.startHour === selectedSession.startHour &&
                    session.endHour === selectedSession.endHour
            );

            const updatedSessions = updateSessionsArray({
                sessions,
                sessionIndex,
                booking,
                isWaitlist,
            });

            handleSessionsChange({ optimisticSessions: updatedSessions });
        }
    };

    if (memberhipBookingLoading) {
        return (
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
                <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(10px)" />
                <ModalContent bg="gray.800" borderRadius="md" boxShadow="lg">
                    <ModalHeader
                        textAlign="center"
                        fontSize="2xl"
                        fontWeight="bold"
                        color="blue.200"
                        py={6}
                    >
                        {isWaitlist ? "Waitlisting..." : "Booking..."}
                    </ModalHeader>
                    <ModalBody>
                        <Loading height="18vh" width="600px" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(10px)" />
            <ModalContent bg="gray.800" borderRadius="md" boxShadow="lg">
                <ModalHeader
                    textAlign="center"
                    fontSize="2xl"
                    fontWeight="bold"
                    color="blue.200"
                    py={6}
                >
                    {bookingConfirmed
                        ? isWaitlist
                            ? "Waitlisted"
                            : "Booking Confirmed"
                        : isWaitlist
                        ? "Join Waitlist"
                        : "Confirm Booking"}
                </ModalHeader>
                <ModalBody>
                    {selectedSession && !bookingConfirmed && (
                        <>
                            <Text
                                textAlign="center"
                                fontSize="lg"
                                color="white"
                                mb={4}
                            >
                                Are you sure you want to{" "}
                                {isWaitlist ? "join the waitlist for" : "book"}{" "}
                                the{" "}
                                <strong style={{ color: "orange.300" }}>
                                    {formatTime(selectedSession.startHour)} -{" "}
                                    {formatTime(selectedSession.endHour)}
                                </strong>{" "}
                                session on {formatSessionDate(sessionDate)}?
                            </Text>
                        </>
                    )}
                    {bookingConfirmed && (
                        <Text
                            textAlign="center"
                            fontSize="lg"
                            color="white"
                            mb={4}
                        >
                            Your {isWaitlist ? "waitlist" : "booking"} has been
                            confirmed. See you at{" "}
                            {formatSessionStartTimeUtc(sessionStartTime)}.
                        </Text>
                    )}
                </ModalBody>
                <ModalFooter justifyContent="center" py={6}>
                    {!bookingConfirmed && (
                        <Button
                            colorScheme="blue"
                            onClick={handleBookingAttemptClick}
                            mr={4}
                            px={8}
                            py={3}
                            fontSize="md"
                            fontWeight="semibold"
                        >
                            Confirm
                        </Button>
                    )}
                    <Button
                        variant="outline"
                        onClick={onClose}
                        px={8}
                        py={3}
                        fontSize="md"
                        fontWeight="semibold"
                        color="white"
                        borderColor="white"
                        _hover={{ bg: "whiteAlpha.200" }}
                    >
                        {bookingConfirmed ? "Close" : "Cancel"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const updateSessionsArray = ({
    sessions,
    sessionIndex,
    booking,
    isWaitlist,
}) => {
    const updatedSessions = [...sessions];

    if (!isWaitlist) {
        updatedSessions[sessionIndex] = {
            ...updatedSessions[sessionIndex],
            confirmedSet: [
                ...updatedSessions[sessionIndex].confirmedSet,
                booking,
            ],
            spotsRemaining: updatedSessions[sessionIndex].spotsRemaining - 1,
        };
    } else {
        updatedSessions[sessionIndex] = {
            ...updatedSessions[sessionIndex],
            waitlistSet: [
                ...updatedSessions[sessionIndex].waitlistSet,
                booking,
            ],
            waitlistSpotsRemaining:
                updatedSessions[sessionIndex].waitlistSpotsRemaining - 1,
        };
    }

    return updatedSessions;
};
