import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { signUp, confirmSignUp, signIn } from "@aws-amplify/auth";
import { Box, Container, Heading, Text, Image, Button } from "@chakra-ui/react";
import "@aws-amplify/ui-react/styles.css";

import { getOrCreateCustomer } from "../api/getOrCreateCustomer";

const components = {
    Header() {
        return (
            <Box textAlign="center">
                <Image
                    alt="Amplify logo"
                    src="https://docs.amplify.aws/assets/logo-dark.svg"
                />
            </Box>
        );
    },

    Footer() {
        return (
            <Box textAlign="center" pt={4}>
                <Text color="white">&copy; Fenyx Co. 2025</Text>
            </Box>
        );
    },

    SignIn: {
        Header() {
            return (
                <Heading
                    padding={2}
                    level={3}
                    textAlign="center"
                    color="blackAlpha.900"
                >
                    Sign in to your account
                </Heading>
            );
        },
        Footer() {
            const { toForgotPassword } = useAuthenticator();

            return (
                <Box textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toForgotPassword}
                        size="small"
                        variation="link"
                        color="blackAlpha.900"
                    >
                        Reset Password
                    </Button>
                </Box>
            );
        },
    },

    SignUp: {
        Header() {
            return (
                <Heading
                    color="gray.800"
                    textAlign="center"
                    padding={2}
                    level={3}
                >
                    Create a new account
                </Heading>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();

            return (
                <Box textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                        color="blackAlpha.900"
                    >
                        Back to Sign In
                    </Button>
                </Box>
            );
        },
    },
    ConfirmSignUp: {
        Header() {
            return (
                <Heading padding={2} level={3} color="blackAlpha.900">
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();

            return (
                <Box textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                        color="blackAlpha.900"
                    >
                        Back to Sign In
                    </Button>
                </Box>
            );
        },
    },
    SetupTotp: {
        Header() {
            return (
                <Heading padding={2} level={3} color="blackAlpha.900">
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
    ConfirmSignIn: {
        Header() {
            return (
                <Heading padding={2} level={3}>
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
    ForgotPassword: {
        Header() {
            return (
                <Heading padding={2} level={3} color="blackAlpha.900">
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
    ConfirmResetPassword: {
        Header() {
            return (
                <Heading padding={2} level={3}>
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
};

const formFields = {
    signIn: {
        username: {
            placeholder: "Enter your email",
        },
    },
    signUp: {
        username: {
            label: "Username:",
            placeholder: "Enter your username",
            isRequired: true,
            order: 1,
        },
        password: {
            label: "Password:",
            placeholder: "Enter your Password:",
            isRequired: true,
            order: 2,
        },
        confirm_password: {
            label: "Confirm Password:",
            placeholder: "Enter your Password:",
            isRequired: true,
            order: 3,
        },
        given_name: {
            label: "First Name:",
            placeholder: "Enter your first name",
            isRequired: true,
            order: 4,
        },
        family_name: {
            label: "Last Name:",
            placeholder: "Enter your last name",
            isRequired: true,
            order: 5,
        },
        email: {
            label: "Email:",
            placeholder: "Enter your email",
            isRequired: true,
            order: 5,
        },
        address: {
            type: "address",
            label: "Zip Code:",
            placeholder: "Enter your zip code",
            isRequired: true,
            order: 6,
        },
        phone_number: {
            label: "Phone Number:",
            placeholder: "Enter your phone number",
            isRequired: true,
            order: 7,
        },
    },
    forceNewPassword: {
        password: {
            placeholder: "Enter your Password:",
        },
    },
    forgotPassword: {
        username: {
            placeholder: "Enter your email:",
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            placeholder: "Enter your Confirmation Code:",
            label: "New Label",
            isRequired: false,
        },
        confirm_password: {
            placeholder: "Enter your Password Please:",
        },
    },
    setupTotp: {
        QR: {
            totpIssuer: "test issuer",
            totpUsername: "amplify_qr_test_user",
        },
        confirmation_code: {
            label: "New Label",
            placeholder: "Enter your Confirmation Code:",
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            label: "New Label",
            placeholder: "Enter your Confirmation Code:",
            isRequired: false,
        },
    },
};

function Login() {
    const navigate = useNavigate();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const userRef = useRef({});

    useEffect(() => {
        if (authStatus === "authenticated") {
            navigate("/");
        }
    }, [authStatus, navigate]);

    const handleSignUp = async (formData) => {
        try {
            const { username, password, options } = formData;
            const { email, given_name, family_name, phone_number, address } =
                options.userAttributes;

            const signUpData = await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        email,
                        given_name,
                        family_name,
                        phone_number,
                        address,
                    },
                },
            });

            const { nextStep, userId } = signUpData;

            if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
                const userData = {
                    cognitoSub: userId,
                    email,
                    given_name,
                    family_name,
                    phone_number,
                    password,
                    postalCode: address,
                };
                userRef.current = userData;
            }

            // Return the required format
            return signUpData;
        } catch (error) {
            console.error("Error during sign up:", error);
            throw error;
        }
    };

    const handleConfirmSignUp = async ({ username, confirmationCode }) => {
        const signUpData = await confirmSignUp({
            username,
            confirmationCode,
        });

        if (signUpData.isSignUpComplete) {
            const {
                email,
                given_name,
                family_name,
                phone_number,
                password,
                postalCode,
            } = userRef.current;
            await getOrCreateCustomer({
                userData: {
                    email,
                    firstName: given_name,
                    lastName: family_name,
                    phoneNumber: phone_number,
                    postalCode,
                },
            });

            await signIn({
                username,
                password,
            });
        }

        return signUpData;
    };

    return (
        <Container maxW="container.sm" py={8}>
            <Box
                shadow="md"
                rounded="lg"
                bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
                p={6}
            >
                <Heading mb={6} textAlign="center" color="white">
                    Welcome to Fenyx
                </Heading>
                <Authenticator
                    components={components}
                    formFields={formFields}
                    services={{
                        handleSignUp,
                        handleConfirmSignUp,
                    }}
                />
            </Box>
        </Container>
    );
}

export default Login;
