import { useState } from "react";
import { Box, Input, Button, VStack, Text, useToast } from "@chakra-ui/react";

import { createEmailSubscription } from "../../api/createEmailSubscription";

function EmailSignup() {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const json = await createEmailSubscription({ email });
        if (json.message === "Email subscribed successfully") {
            toast({
                title: "Thank you for your interest!",
                description: "We'll keep you updated about our launch.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setEmail("");
        } else {
            toast({
                title: "Error",
                description: "Something went wrong. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    return (
        <Box as="form" onSubmit={handleSubmit} width="100%" maxW="500px">
            <VStack
                spacing={4}
                alignItems="center"
                justifyContent="space-between"
            >
                <Text color="gray.300" textAlign="center">
                    Be the first to know when we launch!
                    <br />
                    Sign up for exclusive updates and early access.
                </Text>
                <Input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    bg="rgba(64, 64, 64, 0.8)"
                    borderColor="gray.600"
                    color="white"
                    _placeholder={{ color: "gray.400" }}
                    _hover={{ borderColor: "maroon.400" }}
                    _focus={{
                        borderColor: "maroon.500",
                        boxShadow: "0 0 0 1px #c70d0d",
                    }}
                    size="lg"
                />
                <Button
                    type="submit"
                    backgroundColor="#1A237E"
                    size="lg"
                    isLoading={isLoading}
                    px={6}
                >
                    Subscribe
                </Button>
                <Text fontSize="sm" color="gray.400">
                    We respect your privacy. No spam, ever.
                </Text>
            </VStack>
        </Box>
    );
}

export default EmailSignup;
