import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { getCustomerByEmail } from "../api/getCustomerByEmail";

const CustomerContext = createContext();

export const useCustomerContext = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }) => {
    const { user, authStatus } = useAuthenticator((context) => [
        context.user,
        context.authStatus,
    ]);
    const [customerData, setCustomerData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (authStatus === "configuring") {
            return;
        }

        const fetchCustomerData = async () => {
            if (authStatus === "authenticated") {
                try {
                    setLoading(true);
                    const userAttributes = await fetchUserAttributes();
                    const authSession = await fetchAuthSession();
                    const jwtToken = authSession.tokens.idToken.toString();
                    const customerDetails = await getCustomerByEmail({
                        email: userAttributes.email,
                        jwtToken,
                    });
                    setCustomerData(customerDetails);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching customer data:", error);
                }
            } else {
                setCustomerData({});
                setLoading(false);
            }
        };

        fetchCustomerData();
    }, [authStatus, user]);

    const memoizedCustomerData = useMemo(() => customerData, [customerData]);

    return (
        <CustomerContext.Provider
            value={{ customerData: memoizedCustomerData, loading }}
        >
            {children}
        </CustomerContext.Provider>
    );
};
