import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { SessionDetails } from "../../components/checkout/SessionDetails";
import { DayPassPaymentComponent } from "../../components/checkout/DayPassPaymentComponent";
import { DayPassStripeWrapper } from "../../components/checkout/DayPassStripeWrapper";
import { DetailsInputForm } from "../../components/checkout/DetailsInputForm";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { getSessions } from "../../api/getSessions";

const isObjectComplete = (obj) =>
    Object.values(obj).every((value) => value !== "");

export const DayPass = () => {
    const navigate = useNavigate();
    const [isDetailsComplete, setIsDetailsComplete] = useState(false);
    const { checkoutData } = useCheckoutContext();
    const { sessionDate, locationId, sessionStartTime } = checkoutData;
    const [spotsRemaining, setSpotsRemaining] = useState(0);
    const { user, authStatus } = useAuthenticator((context) => [
        context.user,
        context.authStatus,
    ]);

    const { data, isLoading, isError, error } = useQuery(
        ["sessions", sessionStartTime],
        () =>
            getSessions({
                sessionDate: sessionDate,
                location: locationId,
            }),
        {
            enabled: sessionDate !== null && locationId !== null,
        }
    );

    useEffect(() => {
        const isCheckoutComplete = isObjectComplete(checkoutData);
        if (!isCheckoutComplete) {
            navigate("/");
        }
    }, [checkoutData, navigate]);

    useEffect(() => {
        const session = data?.sessions?.find(
            (session) =>
                session.startHour ===
                parseInt(getHourFromSessionStartTime(sessionStartTime))
        );
        if (session) {
            setSpotsRemaining(session.spotsRemaining);
        }
    }, [data?.sessions, sessionStartTime]);

    const [customerDetails, setCustomerDetails] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        postalCode: "",
        quantity: 1,
    });

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (authStatus === "authenticated") {
                try {
                    const attributes = await fetchUserAttributes();
                    setCustomerDetails({
                        email: attributes.email,
                        firstName: attributes.given_name,
                        lastName: attributes.family_name,
                        phoneNumber: attributes.phone_number,
                        postalCode: attributes.address,
                        quantity: 1,
                    });
                } catch (error) {
                    console.log("Error fetching user details:", error);
                }
            }
        };

        fetchUserDetails();
    }, [authStatus]);

    const handleCustomerDetailsChange = ({ field, value }) => {
        setCustomerDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        const isDetailsComplete = isObjectComplete(customerDetails);
        setIsDetailsComplete(isDetailsComplete);
    };

    return (
        <Box
            maxW="1200px"
            mx="auto"
            mt={8}
            p={8}
            bg="linear-gradient(135deg, rgba(0, 0, 30, 1) 0%, rgba(55, 1, 25, 1) 100%)"
            borderRadius="2xl"
        >
            <Grid
                templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                gap={8}
            >
                <GridItem>
                    <SessionDetails
                        title="Purchase"
                        customerDetails={customerDetails}
                        onCustomerDetailsChange={handleCustomerDetailsChange}
                    />
                </GridItem>
                <GridItem>
                    {isDetailsComplete ? (
                        <DayPassStripeWrapper customerDetails={customerDetails}>
                            <DayPassPaymentComponent />
                        </DayPassStripeWrapper>
                    ) : (
                        <DetailsInputForm
                            customerDetails={customerDetails}
                            onCustomerDetailsChange={
                                handleCustomerDetailsChange
                            }
                            onSubmit={handleSubmit}
                            spotsRemaining={spotsRemaining}
                            type="day-pass"
                        />
                    )}
                </GridItem>
            </Grid>
        </Box>
    );
};

const getHourFromSessionStartTime = (sessionStartTime) => {
    const momentDate = moment.utc(sessionStartTime, "YYYY-MM-DDTHH:mm:ss");
    const start = momentDate.tz("America/Denver");
    const hour = start.format("HH");
    return hour;
};
