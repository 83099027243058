const API_BASE_URL = process.env.REACT_APP_BOOKINGS_API_BASE_URL;

export const confirmSessionAvailable = async ({ bookingData }) => {
    const pathWithParams = addParamsToPath({
        path: `${API_BASE_URL}/check-availability`,
        params: bookingData,
    });

    const response = await fetch(pathWithParams, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        if (response.status === 400) {
            return {
                isAvailable: false,
                message: "Session is no longer available",
            };
        }
        throw new Error("Failed to confirm session availability");
    }

    return response.json();
};

const addParamsToPath = ({ path, params }) => {
    return (
        path +
        "?" +
        Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
    );
};
